<template>
  <v-container>
    <v-row
      no-gutters
      class="mt-10"
    >
      <v-col
        class="my-2"
        cols="12"
      >
        <v-row no-gutters>
          <v-col
            class="pa-1"
            cols="12"
            md="3"
          >
            <v-select
              :value="$route.query.branch || ''"
              label="Koondis"
              :items="branches"
              item-text="name"
              item-value="value"
              hide-details
              dense
              :disabled="editMode"
              outlined
              clearable
              :append-icon="''"
              :clear-icon="mdiClose"
              @change="onInputChange('branch', $event)"
            />
          </v-col>
          <v-col
            class="pa-1"
            cols="12"
            md="3"
          >
            <v-autocomplete
              :search-input.sync="categorySearch"
              :value="$route.query.categoryId || ''"
              dense
              outlined
              :items="filteredLibraryCategories"
              item-text="category"
              item-value="id"
              label="Kategooria"
              hide-details
              clearable
              :append-icon="''"
              :clear-icon="mdiClose"
              :disabled="editMode"
              @change="onInputChange('categoryId', $event)"
            >
              <template #item="{item, on, attrs}">
                <v-list-item
                  v-bind="attrs"
                  dense
                  v-on="on"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ item['category'] }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action
                    v-if="canEditLibrary"
                    class="my-0"
                  >
                    <v-btn
                      icon
                      small
                      @click.stop="onCategoryEdit(item)"
                    >
                      <v-icon small>
                        {{ mdiPencil }}
                      </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
              <template #no-data>
                <v-list

                  dense
                  class="py-0"
                >
                  <v-list-item
                    v-if="!!$route.query.branch && canEditLibrary"
                    @click="onAddCategory"
                  >
                    <v-list-item-icon class="mr-2">
                      <v-icon color="primary">
                        {{ mdiPlus }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        Lisa uus kategooria: <span class="font-weight-bold">{{ categorySearch }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-else-if="!!$route.query.branch">
                    <v-list-item-content>
                      <v-list-item-title>
                        Vastet ei leitud
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-else>
                    <v-list-item-content>
                      <v-list-item-title>
                        Kategooriate kuvamiseks vali esmalt koondis
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            class="pa-1"
            cols="12"
            md="3"
          >
            <!-- :disabled="true" -->
            <v-text-field
              :value="$route.query.search || ''"
              label="Otsing"
              dense
              outlined
              hide-details
              :disabled="editMode"
              @input="onInputChange('search', $event)"
            />
          </v-col>
          <v-col
            v-if="canEditLibrary"
            cols="12"
            class="pa-1"
            md="3"
          >
            <v-row
              no-gutters
              align="center"
              justify="end"
            >
              <v-btn
                v-if="editMode"
                color="primary"
                text
                @click="onCancelEdit"
              >
                <span>Tühista</span>
              </v-btn>
              <v-btn
                v-else
                color="primary"
                @click="onEnterEditMode"
              >
                <v-icon left>
                  {{ mdiPlus }}
                </v-icon>
                <span>Lisa uus raamat</span>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="editMode"
        class="my-2"
        cols="12"
      >
        <LibraryEditBookForm
          :current-edit-book="currentEditBook"
          @on-book-save="onBookSave"
        />
      </v-col>
      <v-col
        class="my-2"
        cols="12"
      >
        <v-data-table
          dense
          :items="libraryBooks"
          :headers="tableHeaders"
          hide-default-footer
          :items-per-page="-1"
        >
          <template #item.categoryId="{ value }">
            {{ categoryMap[value] ? categoryMap[value].category : "" }}
          </template>
          <template
            v-if="canEditLibrary"
            #item.edit="{ item }"
          >
            <v-btn
              small
              icon
              color="primary"
              @click="onModifyBook(item)"
            >
              <v-icon small>
                {{ mdiPencil }}
              </v-icon>
            </v-btn>
            <v-btn
              small
              icon
              color="error"
              @click="onDeleteBook(item)"
            >
              <v-icon small>
                {{ mdiDelete }}
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {
  reactive, toRefs, watch, computed, onMounted,
} from '@vue/composition-api';
import {
  mdiPlus, mdiPencil, mdiDelete, mdiClose,
} from '@mdi/js';
import LibraryEditBookForm from '@/components/organisms/LibraryEditBookForm/index.vue';
import { orgBranches } from '@/helpers/common-data';
import libaryModule from '@/store/modules/library';
import dialogModule from '@/store/modules/dialog';
import searchModule from '@/store/modules/search';
import authModule from '@/store/modules/auth';
import router from '@/router';

const vectorIcons = {
  mdiPlus, mdiPencil, mdiDelete, mdiClose,
};

export default {
  /* eslint-disable no-underscore-dangle */
  components: { LibraryEditBookForm },
  setup(props, ctx) {
    const { canEditLibrary } = authModule();
    const { performSearch, results } = searchModule();
    const {
      fetchLibraryCategories,
      categoryMap,
      libraryCategories,
      libraryBooks,
      saveBook,
      deleteBook,
      setLibraryBooks,
    } = libaryModule();
    const {
      setSnackBarOptions, openSnackBar, setConfirmOptions, openConfirmDialog, setDialogOptions, openDialog,
    } = dialogModule();
    const orgBranch = computed(() => ctx.root._route.query.branch || '');
    const categoryId = computed(() => ctx.root._route.query.categoryId || '');
    const bookSearch = computed(() => ctx.root._route.query.search || '');
    const filterBooks = () => {
      performSearch({
        searchString: bookSearch.value,
        entityTypes: ['books'],
        filters: [
          ['categoryId', '==', categoryId.value],
          ['branch', '==', orgBranch.value],
        ],
      });
    };
    onMounted(() => {
      fetchLibraryCategories();
      filterBooks();
    });
    const branches = computed(() => orgBranches.filter((b) => b.value !== 'vilkogu'));
    const state = reactive({
      categorySearch: '',
      currentEditBook: null,
    });
    const editMode = computed(() => !!state.currentEditBook);

    const onInputChange = (field, value) => {
      router.replace({ query: { ...ctx.root._route.query, [field]: value } });
    };
    watch(results, (val) => {
      setLibraryBooks(val);
    });
    watch(orgBranch, () => {
      onInputChange('categoryId', '');
      filterBooks();
    });
    watch(categoryId, () => {
      filterBooks();
    });
    watch(bookSearch, () => {
      filterBooks();
    });
    const filteredLibraryCategories = computed(() => {
      if (!libraryCategories) return [];
      return libraryCategories.value.filter((cat) => cat.branch === orgBranch.value);
    });
    const onModifyBook = (book) => {
      state.currentEditBook = { ...book };
      onInputChange('search', book.name);
      onInputChange('categoryId', book.categoryId);
      onInputChange('branch', book.branch);
    };

    const onDeleteBook = (book) => {
      const onDeleteBookConfirm = async () => {
        try {
          await deleteBook(book);
          setSnackBarOptions({
            text: `${book.name} edukalt kustutatud!`,
            color: 'success',
          });
        } catch (err) {
          setSnackBarOptions({
            text: `${book.name} kustutamine ebaõnnestus!`,
            color: 'error',
          });
        }
        openSnackBar();
      };
      setConfirmOptions({
        title: `${book.name} kustutamine`,
        text: 'Oled kindel et soovid jätkata?',
        confirm_text: 'Kustuta',
        cancel_text: 'Loobu',
        action: () => onDeleteBookConfirm(),
      });
      openConfirmDialog();
    };
    const onEnterEditMode = () => {
      state.currentEditBook = {
        name: bookSearch.value,
        categoryId: categoryId.value,
        branch: orgBranch.value,
        description: '',
        author: '',
        published: null,
        count: 1,
      };
    };
    const onCancelEdit = () => {
      state.currentEditBook = null;
    };
    const onBookSave = async (book) => {
      try {
        await saveBook(book);
        setSnackBarOptions({
          text: book.name + (book.id ? ' edukalt muudetud!' : ' edukalt lisatud!'),
          color: 'success',
        });
        state.currentEditBook = null;
      } catch (err) {
        setSnackBarOptions({
          text: `${book.name} lisamine ebaõnnestus!`,
          color: 'error',
        });
      }
      openSnackBar();
    };
    const onCategoryEdit = (category) => {
      setDialogOptions({
        component: 'LibraryEditCategoryForm',
        payload: {
          category: { ...category },
          action: (cId) => {
            onInputChange('categoryId', cId);
            state.categorySearch = '';
          },
        },
      });
      openDialog();
    };
    const onAddCategory = () => {
      setDialogOptions({
        component: 'LibraryEditCategoryForm',
        payload: {
          category: {
            category: state.categorySearch,
            branch: orgBranch.value,
          },
          action: (cId) => {
            onInputChange('categoryId', cId);
            state.categorySearch = '';
          },
        },
      });
      openDialog();
    };
    const tableHeaders = [
      {
        text: 'Raamat',
        value: 'name',
        sortable: false,
      },
      {
        text: 'Kirjeldus',
        value: 'description',
        sortable: false,
      },
      {
        text: 'Autor',
        value: 'author',
        sortable: false,
      },
      {
        text: 'Kategooria',
        value: 'categoryId',
        sortable: false,
      },
      {
        text: 'Publitseeritud',
        value: 'published',
        sortable: false,
      },
      {
        text: 'Kogus',
        value: 'count',
        sortable: false,
      },
      {
        text: 'Asukoht',
        value: 'branch',
        sortable: false,
        cellClass: 'text-capitalize',
      },
      {
        text: '',
        value: 'edit',
        sortable: false,
        cellClass: 'px-0',
        width: 60,
      },
    ];

    return {
      ...toRefs(state),
      ...vectorIcons,
      canEditLibrary,
      filteredLibraryCategories,
      libraryBooks,
      tableHeaders,
      filterBooks,
      results,
      branches,
      categoryMap,
      onInputChange,
      onModifyBook,
      onDeleteBook,
      editMode,
      onEnterEditMode,
      onCancelEdit,
      onBookSave,
      onAddCategory,
      onCategoryEdit,
    };
  },
};
</script>
