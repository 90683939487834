<template>
  <img
    v-if="allowed_image_extensions.includes(current_file_ext)"
    :src="current_url"
    contain
    class="image-size"
  >

  <!-- <object
            v-else-if="iana_mapping[current_file_ext] && allowed_media_extensions.includes(current_file_ext)"
            :data="current_url"
            :type="iana_mapping[current_file_ext]"
            width="100%"
            style="max-height=90vh !important; max-width='90vw' !important"
        >
            <a :href="current_url">Download</a>
        </object>
        <iframe
            v-else
            :src="google_url"
            style="max-height=90vh !important; max-width='90vw' !important; min-height='90vw' width='90vw'"
        /> -->
  <!-- v-if="allowed_pdf_extensions.includes(current_file_ext)" -->
</template>
<script>
export default {
  props: {
    payload: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      allowed_image_extensions: ['png', 'jpeg', 'bmp', 'gif', 'jpg'],
      allowed_pdf_extensions: ['pdf', 'txt'],
      allowed_media_extensions: ['aac', 'avi', 'mid', 'midi', 'mp3', 'mpeg'],
      iana_mapping: {
        aac: 'audio/aac',
        abw: 'application/x-abiword',
        arc: 'application/x-freearc',
        avi: 'video/x-msvideo',
        azw: 'application/vnd.amazon.ebook',
        bin: 'application/octet-stream',
        bmp: 'image/bmp',
        bz: 'application/x-bzip',
        bz2: 'application/x-bzip2',
        csh: 'application/x-csh',
        css: 'text/css',
        csv: 'text/csv',
        doc: 'application/msword',
        docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        eot: 'application/vnd.ms-fontobject',
        epub: 'application/epub+zip',
        gz: 'application/gzip',
        gif: 'image/gif',
        htm: 'text/html',
        html: 'text/html',
        png: 'image/png',
        pdf: 'application/pdf',
        ico: 'image/vnd.microsoft.icon',
        ics: 'format text/calendar',
        jar: 'application/java-archive',
        jpeg: 'image/jpeg',
        jpg: 'image/jpeg',
        js: 'JavaScript text/javascript',
        json: 'application/json',
        jsonld: 'application/ld+json',
        mid: 'audio/midi audio/x-midi',
        midi: 'audio/midi audio/x-midi',
        mjs: 'text/javascript',
        mp3: 'audio/mpeg',
        mpeg: 'video/mpeg',
        wav: 'audio/wav',
        weba: 'audio/webm',
        webm: 'video/webm',
        // .mpkg Apple Installer Package application/vnd.apple.installer+xml
        // .odp OpenDocument presentation document application/vnd.oasis.opendocument.presentation
        // .ods OpenDocument spreadsheet document application/vnd.oasis.opendocument.spreadsheet
        // .odt OpenDocument text document application/vnd.oasis.opendocument.text
        // .oga OGG audio audio/ogg
        // .ogv OGG video video/ogg
        // .ogx OGG application/ogg
        // .opus Opus audio audio/opus
        // .otf OpenType font font/otf

        // .php Hypertext Preprocessor (Personal Home Page) application/php
        // .ppt Microsoft PowerPoint application/vnd.ms-powerpoint
        // .pptx Microsoft PowerPoint (OpenXML)
        // application/vnd.openxmlformats-officedocument.presentationml.presentation
        // .rar RAR archive application/x-rar-compressed
        // .rtf Rich Text Format (RTF) application/rtf
        // .sh Bourne shell script application/x-sh
        // .svg Scalable Vector Graphics (SVG) image/svg+xml
        // .swf Small web format (SWF) or Adobe Flash document application/x-shockwave-flash
        // .tar Tape Archive (TAR) application/x-tar
        // .tif
        // .tiff Tagged Image File Format (TIFF) image/tiff
        // .ts MPEG transport stream video/mp2t
        // .ttf TrueType Font font/ttf
        // .txt Text, (generally ASCII or ISO 8859-n) text/plain
        // .vsd Microsoft Visio application/vnd.visio

        // .webp WEBP image image/webp
        // .woff Web Open Font Format (WOFF) font/woff
        // .woff2 Web Open Font Format (WOFF) font/woff2
        // .xhtml XHTML application/xhtml+xml
        // .xls Microsoft Excel application/vnd.ms-excel
        // .xlsx Microsoft Excel (OpenXML) application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
        // .xml XML application/xml if not readable from casual users (RFC 3023, section 3)
        // text/xml if readable from casual users (RFC 3023, section 3)
        // .xul XUL application/vnd.mozilla.xul+xml
        // .zip ZIP archive application/zip
        // .3gp 3GPP audio/video container video/3gpp
        // audio/3gpp if it doesn't contain video
        // .3g2 3GPP2 audio/video container video/3gpp2
        // audio/3gpp2 if it doesn't contain video
        // .7z 7-zip archive application/x-7z-compressed
      },
    };
  },
  computed: {
    current_url() {
      if (this.payload && this.payload.url) {
        return this.payload.url;
      }
      return '';
    },
    google_url() {
      return `https://docs.google.com/viewer?url=${encodeURIComponent(this.current_url)}&embedded=true`;
    },
    current_file_ext() {
      if (this.payload && this.payload.file_ext) {
        return this.payload.file_ext;
      }
      return '';
    },
  },
};
</script>
<style  lang="scss">
.image-size {
    max-height: 80vh;
    max-width: 80vw;
}
//todo:: v-dialog override global class
.v-dialog {
    width: auto !important;
}
</style>
