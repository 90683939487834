<template>
  <v-col>
    <v-row
      no-gutters
      justify="space-between"
    >
      <span class="text-caption">{{ currentDirectoryItems.length }} pilti või albumit</span>
      <span>
        <v-tooltip
          v-if="canEditGallery"
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="onImageUploadClick({fullPath: $route.path})"
            >
              <v-icon>{{ mdiImagePlus }}</v-icon>
            </v-btn>
          </template>
          <span>Lisa pilte</span>
        </v-tooltip>
        <v-tooltip
          v-if="canEditGallery"
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="onCreateDirectoryClick($route.path)"
            >
              <v-icon>{{ mdiFolderPlus }}</v-icon>
            </v-btn>
          </template>
          <span>Loo kaust</span>
        </v-tooltip>
        <v-tooltip
          v-if="canEditGallery"
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="onZipUploadClick({fullPath: $route.path})"
            >
              <v-icon>{{ mdiArchiveArrowUp }}</v-icon>
            </v-btn>
          </template>
          <span>Lisa arhiiv piltidega (.zip)</span>
        </v-tooltip>
      </span>
    </v-row>
    <v-row
      no-gutters
      class="justify-center fill-height cols masonry-container-width"
    >
      <masonry
        :cols="{ default: 8, 2500: 8, 1900: 7, 1600: 6, 1500: 5, 1400: 4, 1000: 3, 800: 2, 425: 1 }"
        :gutter="{ default: '30px', 700: '15px' }"
        class="masonry-width"
      >
        <template v-for="(item, index) in currentDirectoryItems">
          <v-card
            v-if="item.type === 'directory'"
            :key="'directory-' + index"
            class="mx-auto my-4"
            @click="$router.push('/' + item.filePath).catch(() => {})"
          >
            <v-img
              :key="index"
              :src="item.thumbUrl"
              height="194"
            />
            <v-card-actions>
              <span class="title-ellipsis">
                {{ item.fileName }}
              </span>
            </v-card-actions>
          </v-card>
          <img
            v-else
            :key="'file-' + index"
            :src="item.thumbUrl"
            class="ma-2 img-size"
            @click="onItemClick(item)"
          >
        </template>
      </masonry>
    </v-row>
  </v-col>
</template>
<script>
/* eslint-disable no-underscore-dangle */
import {
  reactive, toRefs, watch, computed, onMounted,
} from '@vue/composition-api';
import fileTypes from '@/composables/use-file-type-definitions';
import galleryModule from '@/store/modules/gallery';
import storageModule from '@/store/modules/storage';
import authModule from '@/store/modules/auth';
import dialogModule from '@/store/modules/dialog';
import { mdiImagePlus, mdiFolderPlus, mdiArchiveArrowUp } from '@mdi/js';

const vectorIcons = { mdiImagePlus, mdiFolderPlus, mdiArchiveArrowUp };
export default {
  components: {},
  props: {},
  setup(props, ctx) {
    const { canEditGallery } = authModule();
    const { fetchPhotosPath } = galleryModule();
    const { getBucketFileUrl } = storageModule();
    const {
      openDialog, setDialogOptions, openOverlay, setOverlayOptions,
    } = dialogModule();
    const state = reactive({
      currentDirectoryItems: [],
      files: fileTypes,
    });
    const route = computed(() => ctx.root._route);
    const loadPhotosPath = async (path) => {
      const directoryItems = await fetchPhotosPath({ path });
      // const directoryItems = await this.getBucketDirectory({storagePath: this.$route.path});
      // directoryItems.filter(i => this.hasThumbnail(i) || !i.file)
      state.currentDirectoryItems = directoryItems;
    };
    watch(route, (to, from) => {
      if (from && to.path !== from.path) {
        loadPhotosPath(to.path);
      }
    });
    const getFileExt = (fileName) => {
      if (fileName) {
        // eslint-disable-next-line no-bitwise
        return fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2).toLowerCase();
      }
      return '';
    };
    const navigateToGalleryItem = async (item) => {
      const path = item.r1000FilePath;
      const url = await getBucketFileUrl({
        storagePath: path,
      });
      const currentIndex = state.currentDirectoryItems.findIndex((i) => i.r1000FilePath === path);
      const next = async () => {
        let nextIndex = currentIndex + 1;
        if (nextIndex >= state.currentDirectoryItems.length) nextIndex = 0;
        const nextItem = state.currentDirectoryItems[nextIndex];
        await navigateToGalleryItem(nextItem);
      };
      const previous = async () => {
        let previousIndex = currentIndex - 1;
        if (previousIndex < 0) previousIndex = state.currentDirectoryItems.length - 1;
        const previousItem = state.currentDirectoryItems[previousIndex];
        await navigateToGalleryItem(previousItem);
      };
      setOverlayOptions({
        component: 'DocumentPreview',
        payload: {
          file: path,
          file_ext: getFileExt(path),
          url,
          next,
          previous,
        },
      });
    };
    const onItemClick = async (item) => {
      navigateToGalleryItem(item);
      openOverlay();
    };
    const onImageUploadClick = (directoryItem) => {
      const rules = [];
      setDialogOptions({
        width: 400,
        component: 'FileUploadForm',
        payload: { directoryItem, rules, accept: 'image/*' },
      });
      openDialog();
    };
    const onCreateDirectoryClick = (fullPath) => {
      setDialogOptions({
        width: 400,
        component: 'CreateDirectoryForm',
        payload: { fullPath, collection: 'photos' },
      });
      openDialog();
    };
    const onZipUploadClick = (directoryItem) => {
      const rules = [];
      setDialogOptions({
        width: 400,
        component: 'FileUploadForm',
        payload: { directoryItem, rules, accept: '.zip' },
      });
      openDialog();
    };
    onMounted(() => {
      loadPhotosPath(decodeURI(route.value.path));
    });
    return {
      ...toRefs(state),
      ...vectorIcons,
      onImageUploadClick,
      onZipUploadClick,
      onCreateDirectoryClick,
      onItemClick,
      canEditGallery,
    };
  },
};
</script>
<style scoped lang="scss">
.title-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    width: 200px;
}
/* .v-treeview-node .treeview-actions {
        visibility: hidden;
        opacity: 0;
    }

    .v-treeview-node:hover .treeview-actions {
        visibility: visible;
        opacity: 1;
    } */
.masonry-width {
    width: 100%
}
.masonry-container-width {
    max-width: 100%
}
.img-size {
   width: 100%;
   height: auto;
}
</style>
