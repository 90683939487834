import {
  inject, provide, reactive, toRefs, computed, isRef,
} from '@vue/composition-api';
import { groupMembership } from '../../api';
import useBindCollection from '../../composables/firebase/use-bind-collection';

export const state = reactive({
  list: [],
  groupLoading: false,
});
export const actions = {
  async addGroupMember({ groupId, email }) {
    let response;
    try {
      state.groupLoading = true;
      response = await groupMembership.post({ groupId, email });
    } catch (err) {
      console.log(err);
    } finally {
      state.groupLoading = false;
    }
    return response;
  },
  async removeGroupMember({ groupId, email }) {
    let response;
    try {
      state.groupLoading = true;
      response = await groupMembership.delete({ groupId, email });
    } finally {
      state.groupLoading = false;
    }
    return response;
  },
  async fetchGroups() {
    const { bindCollection } = useBindCollection(state.list, 'google_groups', {});
    bindCollection();
  },
};

export const getters = {
  groups: computed(() => state.list),
  getGroup: (groupId) => computed(() => {
    const gId = isRef(groupId) ? groupId.value : groupId;
    return state.list.find((group) => group.id === gId);
  }),
};

export const key = Symbol('GroupStore');

export const provideGroup = () => provide(key, {
  ...toRefs(state),
  ...getters,
  ...actions,
});

export default () => inject(key);
