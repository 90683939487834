import {
  inject, provide, reactive, toRefs, computed,
} from '@vue/composition-api';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { state as authState } from './auth';
import { storageDirectory } from '../../api';

export const state = reactive({
  directory: null,
  directoryItems: [],
  uploadQueue: {},
});
export const actions = {
  async createStorageDirectory({ path, collection }) {
    try {
      console.log('asd', path, collection);
      await storageDirectory.post({ path, collection });
    } catch (error) {
      console.log('err', error);
    }
  },
  async getBucketDirectory({ storagePath = '' } = {}) {
    const storage = firebase.storage();
    const storageRef = storage.ref();
    const listRef = storageRef.child(storagePath);

    let response;
    try {
      response = await listRef.listAll();
      const folders = response.prefixes.map((i) => ({
        fullPath: i.fullPath,
        name: i.name,
        children: [],
      }));
      const files = response.items.map((i) => ({
        fullPath: i.fullPath,
        name: i.name,
        // eslint-disable-next-line no-bitwise
        file: i.name.slice(((i.name.lastIndexOf('.') - 1) >>> 0) + 2).toLowerCase(),
      }));
      return [...folders, ...files];
    } catch (err) {
      // pass
    }
    return response;
  },

  async getBucketFileUrl({ storagePath } = {}) {
    const storage = firebase.storage();
    const storageRef = storage.ref();
    let response;
    try {
      response = await storageRef.child(storagePath).getDownloadURL();
    } catch (err) {
      try {
        const fixedMaybe = storagePath.replace('õ', 'õ');
        response = await storageRef.child(fixedMaybe).getDownloadURL();
      } catch (err2) {
        // pass
      }
    }
    return response;
  },
  uploadFileAsPromise({
    file, directory, onFinalize, name,
  } = {}) {
    return new Promise(((resolve, reject) => {
      const fullDirectory = directory;
      let fileName;
      if (file instanceof Blob) {
        fileName = name || `file_uploaded_${new Date().toISOString()}`;
      }
      if (file instanceof File) {
        fileName = name || file.name;
      }
      const storage = firebase.storage();
      const uploadPath = `${fullDirectory}/${fileName}`;
      const storageRef = storage.ref(uploadPath);

      const { uid } = authState.user;
      const createdAt = new Date().toISOString();
      const metadata = {
        cacheControl: 'public,max-age=300',
        customMetadata: {
          uploaded_by: uid,
          createdAt,
        },
      };
      // Upload file
      const task = storageRef.put(file, metadata);

      // Update progress bar
      state.uploadQueue = {
        ...state.uploadQueue,
        [uploadPath]: {
          id: uploadPath, name: fileName, percentage: 0, date: createdAt, status: 'started',
        },
      };
      task.on(
        'state_changed',
        (snapshot) => {
          const percentage = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          state.uploadQueue[uploadPath].percentage = percentage;
          state.uploadQueue[uploadPath].status = 'in_progress';
        },
        () => {
          state.uploadQueue[uploadPath].status = 'failed';
          reject();
        },
        () => {
          // const downloadURL = task.snapshot.downloadURL;
          state.uploadQueue[uploadPath].status = 'complete';
          if (onFinalize) onFinalize();
          resolve();
        },
      );
    }));
  },
  async fetchDirectoryItem({ item, rootpath }) {
    if (item && item.fullPath) {
      const items = await actions.getBucketDirectory({
        storagePath: item.fullPath,
      });
      // eslint-disable-next-line no-param-reassign
      item.children = [...items];
    } else {
      const items = await actions.getBucketDirectory({
        storagePath: rootpath,
      });
      state.directoryItems = items;
    }
  },
  clearUploadQueue() {
    state.uploadQueue = {};
  },
};

export const getters = {
  threads: computed(() => state.list),
  directoryItems: computed(() => state.directoryItems),
  uploadQueue: computed(() => Object.values(state.uploadQueue)),
};
export const key = Symbol('StorageStore');

export const provideStorage = () => provide(key, {
  ...toRefs(state),
  ...getters,
  ...actions,
});

export default () => inject(key);
