import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VueMasonry from 'vue-masonry-css';

import './plugins/composition-plugin';
import './plugins/vee-validate';

import vuetify from './plugins/vuetify';
import router from './router';
import './registerServiceWorker';

import App from './App.vue';
// import 'roboto-fontface/css/roboto/roboto-fontface.css'

Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(VueMasonry);

// let app = "";
new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

// firebase.initializeApp({
//     apiKey: "AIzaSyBAt00k-atetNROlYuGwdyf2wr2hKYsoE8",
//     authDomain: "pohjala-siseveeb-eu.firebaseapp.com",
//     databaseURL: "https://pohjala-siseveeb-eu.firebaseio.com",
//     projectId: "pohjala-siseveeb-eu",
//     storageBucket: "pohjala-siseveeb-eu.appspot.com",
//     messagingSenderId: "265678499855"
// });
// firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
// firebase.getCurrentUser = () => {
//     return new Promise((resolve, reject) => {
//         const unsubscribe = firebase.auth().onAuthStateChanged(user => {
//             unsubscribe();
//             resolve(user);
//         }, reject);
//     });
// };
// firebase.auth().onAuthStateChanged(async user => {
//     if (user && store.getters["auth/user"]) {
//         if (!store.getters["auth/token"]) {
//             await store.dispatch("auth/fetchToken", user);
//         }
//         await store.dispatch("auth/fetchClaims", user);
//         store.commit("auth/setUser", user);
//     }
//     if (!app) {
//         app = new Vue({
//             router,
//             store,
//             vuetify,
//             render: h => h(App)
//         }).$mount("#app");
//     }
// });
