export function updateListState(id, data, list, id_key = 'id', addAsLast = false) {
  const listIndex = list.findIndex((t) => t[id_key] === id);
  if (data) {
    if (listIndex > -1) {
      list.splice(listIndex, 1, Object.assign(list[listIndex], data));
    } else if (addAsLast) {
      list.push(data);
    } else {
      list.unshift(data);
    }
  } else if (listIndex > -1) {
    list.splice(listIndex, 1);
  }
}
export function placeholder() {
  return false;
}
