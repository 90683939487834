import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import {
  computed,
  inject, provide, reactive, toRefs,
} from '@vue/composition-api';
import { auth, rightsCookie } from '../../api';
import router from '../../router';

export const state = reactive({
  uid: '',
  token: '',
  claims: {},
  prevRoute: { path: '/' },
  user: {
    uid: null,
    name: '',
    email: '',
    photoUrl: '',
  },
});
export const actions = {
  hasClaimTo(claimsList) {
    if (!claimsList || !Array.isArray(claimsList)) return false;
    return claimsList.some(((claim) => !!state.claims && claim in state.claims && state.claims[claim] === true));
  },
  async fetchClaims(payload) {
    if (payload) {
      const idTokenResult = await payload.getIdTokenResult();
      state.claims = idTokenResult.claims;
    } else {
      console.log('fetchClaims no payload', payload);
    }
  },
  async fetchToken(payload) {
    if (payload) {
      const idToken = await payload.getIdToken();
      state.token = idToken;
    } else {
      console.log('fetchToken no payload', payload);
    }
  },
  async signUserIn({ email, password }) {
    const user = await firebase.auth().signInWithEmailAndPassword(email, password);
    state.user = user;
    await actions.fetchToken(user.user);
    // await actions.postIdTokenToSessionLogin();
  },
  async suggestEmail(email) {
    let response;
    try {
      response = await auth.suggest(email);
      return response;
    } catch (err) {
      // pass
    }
    return response;
  },
  async resetPasswordWithEmail({ email }) {
    await firebase.auth().sendPasswordResetEmail(email);
  },
  async signUserOut() {
    try {
      await rightsCookie.unset();
      await firebase.auth().signOut();
    } catch (error) {
      state.error = error;
    }
  },
};

export const getters = {
  canEditProfile: (profileId) => computed(() => {
    if (state.user.uid === profileId) return true;
    return actions.hasClaimTo(['superadmin', 'admin']);
  }),
  canEditLibrary: computed(() => actions.hasClaimTo(['superadmin', 'admin', 'raamatukoguvanem'])),
  canSeeExperimental: computed(() => actions.hasClaimTo(['superadmin', 'admin'])),
  canEditGallery: computed(() => actions.hasClaimTo(['superadmin', 'admin', 'kroonik'])),
};

export const key = Symbol('AuthStore');

export const provideAuth = () => {
  firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  firebase.auth().onAuthStateChanged(async (user) => {
    state.user = user;
    await actions.fetchToken(user);
    await actions.fetchClaims(user);
    const entryRouteNames = ['signin'];
    const disallowedRedirectRouteNames = ['signout'];
    const validPreviousRoute = state.prevRoute.fullPath && !disallowedRedirectRouteNames.includes(state.prevRoute.name);
    const validCurrentRoute = entryRouteNames.includes(router.currentRoute.name) && state.prevRoute.name !== router.currentRoute.name;
    if (user?.uid && validCurrentRoute) {
      if (validPreviousRoute) {
        router.push(state.prevRoute.fullPath).catch(() => {});
      } else {
        router.push('/');
      }
    }
  });
  provide(key, {
    ...toRefs(state),
    ...toRefs(getters),
    ...actions,
  });
};
export default () => inject(key);
