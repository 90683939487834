<template>
  <div>
    <v-card
      v-if="
        userProfile && userProfile['education'] && userProfile['education'].length && editMode !== 'education'
      "
      class="ma-3"
    >
      <v-card-title class="py-3">
        <v-icon class="mr-4">
          {{ mdiSchool }}
        </v-icon>
        <span class="text-h6 text--primary">Haridustee</span>
        <v-spacer />
        <v-btn
          v-if="canEdit"
          text
          small
          color="primary"
          @click="onEdit"
        >
          <v-icon left>
            {{ mdiPencil }}
          </v-icon>
          muuda
        </v-btn>
      </v-card-title>
      <v-card-text class="grey--text text--darken-3">
        <v-row
          v-for="(education, eduIdx) in orderBy(userProfile['education'], 'from', 'to', true)"
          :key="'edu-' + eduIdx"
          no-gutters
        >
          <v-col
            class="shrink"
            cols="12"
            md="3"
          >
            {{ education.from }} - {{ education.to }}
          </v-col>
          <v-col
            class="grow"
            cols="12"
            md="9"
          >
            {{ educationLevels[education.level] ? educationLevels[education.level].name : "" }},
            {{ education.speciality }},
            {{ education.institution }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row
      v-else-if="editMode !== 'education' && canEdit"
      no-gutters
      justify="end"
      class="mx-3"
    >
      <v-btn
        text
        small
        color="primary"
        @click="onEdit"
      >
        <v-icon left>
          {{ mdiPencil }}
        </v-icon>
        Lisa haridustee
      </v-btn>
    </v-row>
    <DynamicRowsFormCard
      v-if="userProfile && editMode === 'education'"
      :field-components="studiesRowFields"
      :doc-data="userProfile"
      doc-type="user_profiles"
      doc-key="education"
      card-title="Haridustee lisamine"
      @saved="onCancelEdit"
      @cancelled="onCancelEdit"
    />
  </div>
</template>
<script>
import { mdiPencil, mdiSchool } from '@mdi/js';
import DynamicRowsFormCard from '../molecules/DynamicRowsFormCard.vue';
import { createField } from '../../helpers/form-helpers';
import { orgTitles, educationLevels } from '../../helpers/common-data';
import { orderBy } from '../../helpers/list-helpers';

const vectorIcons = {
  mdiPencil,
  mdiSchool,
};
export default {
  components: {
    DynamicRowsFormCard,
  },
  props: {
    userProfile: {
      type: Object,
      default: () => {},
    },
    editMode: {
      type: String,
      default: '',
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const requiredRules = (label) => [(v) => !!v || `${label} on kohustuslik väli!`];
    const studiesRowFields = [
      createField({
        key: 'institution',
        props: { label: 'Haridusasutus', required: true, rules: requiredRules('Haridusasutus') },
        colProps: {
          cols: 12, sm: 12, md: 12, lg: 6,
        },
      }),
      createField({
        key: 'speciality',
        props: { label: 'Eriala', required: true, rules: requiredRules('Eriala') },
        colProps: {
          cols: 12, sm: 12, md: 12, lg: 6,
        },
      }),
      createField({
        key: 'level',
        component: 'v-select',
        props: {
          label: 'Haridustase',
          required: true,
          rules: requiredRules('Haridustase'),
          items: Object.values(educationLevels),
          'item-text': 'name',
          'item-value': 'value',
        },
        colProps: {
          cols: 12, sm: 12, md: 12, lg: 4,
        },
      }),
      createField({
        key: 'from',
        component: 'date-select-field',
        props: { label: 'Algus', required: true, rules: requiredRules('Algus') },
        colProps: {
          cols: 6, sm: 6, md: 6, lg: 4,
        },
      }),
      createField({
        key: 'to',
        component: 'date-select-field',
        props: { label: 'Lõpp', items: orgTitles },
        colProps: {
          cols: 6, sm: 6, md: 6, lg: 4,
        },
      }),
    ];
    const onEdit = () => {
      emit('update:editMode', 'education');
    };
    const onCancelEdit = () => {
      emit('update:editMode', '');
    };
    return {
      ...vectorIcons,
      educationLevels,
      studiesRowFields,
      orderBy,
      onEdit,
      onCancelEdit,
    };
  },
};
</script>
