<template>
  <v-container>
    <v-row

      no-gutters
    >
      <v-card>
        <iframe
          width="430"
          height="235"
          src="https://thingspeak.com/channels/169160/charts/1?bgcolor=%23ffffff&color=%23d62020&dynamic=true&results=2500&type=line&xaxis=Aeg"
        />
      </v-card>
    </v-row>
  </v-container>
</template>
<script>

export default {
  setup() {
    return {

    };
  },
};

</script>
<style lang="scss" scoped="true" type="text/css">
</style>
