import {
  inject, provide, reactive, toRefs, computed, isRef,
} from '@vue/composition-api';
import useBindCollection from '../../composables/firebase/use-bind-collection';
import { state as authState } from './auth';

export const state = reactive({
  list: [],
  profileLoading: false,
});
export const actions = {
  formatProfileDisplayName: (profile) => {
    const displayStrings = [];
    if (profile) {
      if (profile.org_title) displayStrings.push(profile.org_title);
      if (profile.first_name) displayStrings.push(profile.first_name);
      if (profile.last_name) displayStrings.push(profile.last_name);
    }
    return displayStrings.join(' ');
  },
};

export const getters = {
  profiles: computed(() => state.list),
  getProfile: (profileId) => computed(() => {
    const pId = isRef(profileId) ? profileId.value : profileId;
    return state.list.find((profile) => profile.id === pId);
  }),
  getProfilesInGroup: (groupId) => computed(() => state.list.filter(
    (row) => row.group_memberships
                    && row.group_memberships[groupId]
                    && row.group_memberships[groupId].length,
  )),
  fetchProfiles: () => {
    const { bindCollection } = useBindCollection(state.list, 'user_profiles', {});
    bindCollection();
  },
  currentUserProfile: computed(() => state.list.find((profile) => authState?.user && profile.id === authState.user.uid)),
};

export const key = Symbol('ProfileStore');

export const provideProfile = () => provide(key, {
  ...toRefs(state),
  ...getters,
  ...actions,
});

export default () => inject(key);
