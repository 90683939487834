<template>
  <v-row
    class="mx-1 form-container"
    no-gutters
  >
    <v-col class="xs12 py-2">
      <ValidationObserver v-slot="{ handleSubmit }">
        <v-form @submit.prevent="handleSubmit(onResetPassword)">
          <ValidationProvider
            v-slot="{ errors }"
            name="E-mail"
            rules="required|email"
            mode="eager"
          >
            <v-text-field
              v-model="email"
              :error-messages="errors"
              :disabled="recovery_loading"
              class="email-field"
              label="E-posti aadress"
              required
              dark
              flat
              color="white"
              @change="error_message = ''"
            />
          </ValidationProvider>
          <v-btn
            :disabled="recovery_loading"
            :large="$vuetify.breakpoint.xsOnly"
            :loading="recovery_loading"
            type="submit"
            block
            outlined
            dark
            class="mt-3"
          >
            Saada uus parool
          </v-btn>
          <v-btn
            dark
            block
            small
            text
            class="my-3 pl-2 text-none"
            @click="onReturnToLoginClick"
          >
            <v-icon
              small
              left
            >
              {{ mdiArrowLeft }}
            </v-icon>
            Tagasi sisenema
          </v-btn>
          <div
            class="red--text text-left text-body-2 error-message"
          >
            {{ authErrorMessage }}
          </div>
        </v-form>
      </ValidationObserver>
    </v-col>
    <div class="placeholder" />
  </v-row>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { computed, reactive, toRefs } from '@vue/composition-api';
import { mdiArrowLeft } from '@mdi/js';
import authModule from '../../../store/modules/auth';
import router from '../../../router';

const vectorIcons = { mdiArrowLeft };
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  setup() {
    const { resetPasswordWithEmail, suggestEmail } = authModule();
    const state = reactive({
      recovery_loading: false,
      error_message: '',
      strings: {
        back_to_signin: 'Tagasi sisenema',
        enter: 'Saada uus parool',
      },
      email: '',
      password: '',
      show_password: false,
      suggested_email: '',
      auth_error: '',
    });
    const authErrorMessage = computed(() => {
      if (state.auth_error) {
        if (state.auth_error.code === 'auth/suggest-primary') {
          return state.auth_error.message;
        } if (
          state.auth_error.code === 'auth/user-not-found'
                    || state.auth_error.code === 'auth/invalid-credential'
        ) {
          return 'E-posti aadressi ei leitud!';
        }
        return 'Süsteemi viga!';
      }
      return '';
    });
    const onReturnToLoginClick = () => {
      router.replace({
        name: 'signin',
      });
      state.auth_error = '';
    };
    const onResetPassword = async () => {
      state.auth_error = '';
      state.recovery_loading = true;
      try {
        await resetPasswordWithEmail({
          email: state.email,
        });
        router.push({
          name: 'reset_success',
          params: {
            email: state.email,
          },
        }).catch(() => {});
      } catch (err) {
        state.suggested_email = await suggestEmail(state.email);
        if (state.suggested_email) {
          state.auth_error = {
            code: 'auth/suggest-primary',
            message: `Kontot ei leitud. Kas mõtlesid ${state.suggested_email}?`,
          };
        } else {
          state.auth_error = err;
        }
      }
      state.recovery_loading = false;
    };

    return {
      ...toRefs(state),
      ...vectorIcons,
      authErrorMessage,
      onReturnToLoginClick,
      onResetPassword,
    };
  },
};
</script>
<style scoped lang="scss">
.email-field {
    .v-text-field__details {
        overflow: visible !important;
        white-space: nowrap;
    }

    .v-messages__message {
        white-space: nowrap !important;
    }
}
.form-container {
    max-width: 300px;
}
.error-message {
    height: 20px;
    width: 100%;
    display: block
}
.placeholder {
    height: 90px;
    width: 100%;
    display: block
}
</style>
