<template>
  <v-container>
    <v-row
      justify="center"
      no-gutters
    >
      <v-card
        v-if="currentUserProfile"
        max-width="640px"
        width="100%"
      >
        <v-row no-gutters>
          <v-col
            cols="12"
            class="pa-2"
          >
            <v-autocomplete
              v-model="currentUserProfile.id"
              label="Kasutaja"
              :items="profiles"
              :item-text="formatName"
              item-value="id"
              outlined
              dense
              readonly
            />
          </v-col>
          <v-col cols="12">
            <v-row no-gutters>
              <v-col cols="12">
                <span>{{ resultCode }}</span>
              </v-col>
              <template v-if="productFormData.id">
                <v-col
                  class="pa-2"
                >
                  <v-text-field
                    v-model="productFormData.name"
                    label="toode"
                    outlined
                    dense
                  />
                </v-col>
                <v-col class="pa-2">
                  <v-text-field
                    v-model="productFormData.price"
                    label="hind"
                    outlined
                    dense
                  />
                </v-col>
                <v-col>
                  <v-btn
                    :loading="loading"
                    @click="saveProduct"
                  >
                    Salvesta
                  </v-btn>
                </v-col>
              </template>
            </v-row>
          </v-col>
          <v-col>
            <v-btn @click="toggleScan">
              {{ quaggaActive ? 'Loobu' : 'Skaneeri' }}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
          >
            <div
              ref="quagga"
              class="camera"
            />
          </v-col>
          <!-- <v-col>
            <pre v-if="data">
                {{ data }}
            </pre>
          </v-col> -->
        </v-row>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>

import {
  reactive, toRefs, ref, watch, computed,
} from '@vue/composition-api';
import profileModule from '@/store/modules/profile';
import Quagga from 'quagga';
import useDocument from '@/composables/firebase/use-document';
import { orgTitles } from '@/helpers/common-data';

export default {
  setup() {
    const { currentUserProfile, profiles, error } = profileModule();
    const { getDocument, setDocument } = useDocument('products');
    const quagga = ref(null);
    const state = reactive({
      data: null,
      resultCode: null,
      foundCodes: new Map(),
      product: {},
      productFormData: {},
      quaggaActive: false,
      loading: false,
    });
    async function saveProduct() {
      if (state.productFormData.id && state.productFormData.name && state.productFormData.price) {
        state.loading = true;
        await setDocument(state.productFormData);
        state.loading = false;
      } else {
        console.log('notsaved', state.productFormData, error);
      }
    }
    function formatName(profile) {
      const i = profile;
      return `${(i && i.org_title && orgTitles[i.org_title] && orgTitles[i.org_title].label) || '??'} ${i.first_name} ${i.last_name}`;
    }
    async function getCodeInfo(code) {
      let getinfoflag = false;
      state.foundCodes.forEach((value) => {
        if (value > 10) { getinfoflag = true; }
      });
      if (getinfoflag && state.resultCode === null) {
        const product = await getDocument(code);

        state.resultCode = code;
        if (product && product.id && code) {
          state.productFormData = { ...product };
        } else {
          console.log({ id: code });
          state.productFormData = { id: code };
        }
      }
    }

    function onDetected(data) {
      state.data = data;
      if (state.foundCodes.has(data.codeResult.code)) {
        let val = state.foundCodes.get(data.codeResult.code);
        val += 1;
        state.foundCodes.set(data.codeResult.code, val);
      } else state.foundCodes.set(data.codeResult.code, 1);
      getCodeInfo(data.codeResult.code);
    }

    function stop() {
      Quagga.offDetected(onDetected);
      Quagga.stop();
      quagga.value.querySelector('video').remove();
      quagga.value.querySelector('canvas').remove();
      state.quaggaActive = false;
    }

    const productId = computed(() => state.productFormData.id);
    watch(productId, (val, prev) => {
      if (!prev && val) {
        stop();
      }
    });
    watch(profiles, (val) => {
      if (val) {
        console.log('profiles', JSON.stringify(val));
      }
    });
    function start() {
      state.data = null;
      state.resultcode = null;
      state.foundCodes = new Map();
      state.product = {};
      Quagga.onDetected(onDetected);
      Quagga.start();
      console.log('Quagga started!');
      quagga.value.querySelector('video').style.width = '100%';
      quagga.value.querySelector('canvas').style.width = '100%';
      quagga.value.querySelector('canvas').style.position = 'absolute';
      quagga.value.querySelector('canvas').style.left = 0;
      state.quaggaActive = true;
    }
    function initQuagga() {
      Quagga.init(
        {
          inputStream: {
            name: 'Live',
            type: 'LiveStream',
            target: quagga.value,
            constraints: {
              width: 640,
              height: 480,
              deviceId: 0,
              facingMode: 'environment',
            },
            area: {
              top: '0%',
              right: '0%',
              left: '0%',
              bottom: '0%',
            },

          },
          debug: true,
          locator: {
            halfSample: true,
            patchSize: 'medium', // x-small, small, medium, large, x-large
            debug: {
              showCanvas: true,
              showPatches: true,
              showFoundPatches: true,
              showSkeleton: true,
              showLabels: true,
              showPatchLabels: true,
              showRemainingPatchLabels: true,
              boxFromPatches: {
                showTransformed: true,
                showTransformedBox: true,
                showBB: true,
              },
            },
          },

          locate: true,

          decoder: {
            readers: ['ean_reader'],
          },
          multiple: true,
        },
        () => start(),
      );
    }
    watch(quagga, (value, prev) => {
      if (value && !prev) {
        // initQuagga();
      }
    });
    function toggleScan() {
      if (state.quaggaActive) {
        stop();
      } else {
        initQuagga();
      }
    }
    return {
      ...toRefs(state),
      quagga,
      currentUserProfile,
      toggleScan,
      formatName,
      profiles,
      saveProduct,
    };
  },
};

</script>
<style lang="scss" scoped="true" type="text/css">
// .scanner-wrapper {
//    display: block;
//   max-width: 600px;
//   border-left: 1px solid grey;
//   border-right: 1px solid grey;
// }
// .camera {
//   border: 1px solid red;

//   max-width: 640px;
//   max-height: 480px;
//   display: block;

// }
.camera{
    display: block;
    position: relative;
    width: 100%;

}

</style>
