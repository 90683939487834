<template>
  <v-app id="application">
    <StoreProvider>
      <router-view />
    </StoreProvider>
  </v-app>
</template>
<script>
import StoreProvider from './store';

export default {
  name: 'App',
  components: {
    StoreProvider,
  },
  data() {
    return {};
  },
};
</script>
