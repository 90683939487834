<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    @submit="onSaveBook"
  >
    <v-divider />
    <v-row no-gutters>
      <v-col>
        <v-row no-gutters>
          <v-col
            cols="12"
            xl="2"
            md="3"
            class="pa-1"
          >
            <v-text-field
              v-model.trim="formData.name"
              required
              :rules="[v => !!v || 'Palun sisesta nimi']"
              hide-details
              dense
              outlined
              :label="currentEditBook.name || 'Nimi'"
              maxlength="200"
              @input="onInputChange('search', $event)"
            />
          </v-col>
          <v-col
            cols="12"
            xl="2"
            md="3"
            class="pa-1"
          >
            <v-text-field
              v-model="formData.description"
              hide-details
              dense
              outlined
              label="Kirjeldus"
            />
          </v-col>
          <v-col
            cols="12"
            xl="2"
            md="3"
            class="pa-1"
          >
            <v-text-field
              v-model="formData.author"
              hide-details
              dense
              outlined
              label="Autor"
              required
              :rules="[v => !!v || 'Palun sisesta autor']"
              maxlength="200"
            />
          </v-col>
          <v-col
            cols="12"
            xl="1"
            md="3"
            class="pa-1"
          >
            <v-select
              v-model="formData.branch"
              label="Koondis"
              :items="branches"
              item-text="name"
              item-value="value"
              hide-details
              dense
              outlined
              :rules="[v => !!v || 'Palun vali koondis']"
              @input="onInputChange('branch', $event)"
            />
          </v-col>
          <v-col
            cols="12"
            xl="2"
            md="3"
            class="pa-1"
          >
            <v-autocomplete
              v-model="formData.categoryId"
              dense
              outlined
              :items="filteredLibraryCategories"
              item-text="category"
              item-value="id"
              label="Kategooria"
              hide-details
              required
              :rules="[v => !!v || 'Palun vali kategooria']"
              @input="onInputChange('categoryId', $event)"
            />
          </v-col>
          <v-col
            cols="12"
            xl="1"
            md="3"
            class="pa-1"
          >
            <v-text-field
              v-model="formData.published"
              hide-details
              dense
              outlined
              label="Publitseeritud"
              required
              :rules="[v => !!v || 'Palun sisesta publitseerimisaasta']"
              maxlength="200"
            />
          </v-col>
          <v-col
            cols="12"
            xl="1"
            md="3"
            class="pa-1"
          >
            <v-text-field
              v-model="formData.count"
              hide-details
              dense
              outlined
              label="Kogus"
              required
              :rules="[v => !!v || 'Palun sisesta kogus']"
              maxlength="10"
            />
          </v-col>
          <v-col
            cols="12"
            xl="1"
            md="3"
            class="pa-1"
          >
            <v-row
              no-gutters
              justify="end"
              align="center"
              class="fill-height"
            >
              <v-btn
                v-if="formData.id"
                color="primary"
                @click="onSaveBook"
              >
                <v-icon left>
                  {{ mdiContentSave }}
                </v-icon>
                <span>Muuda</span>
              </v-btn>
              <v-btn
                v-else
                color="primary"
                @click="onSaveBook"
              >
                <v-icon left>
                  {{ mdiContentSave }}
                </v-icon>
                <span>Lisa</span>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider />
  </v-form>
</template>
<script>
import {
  reactive, toRefs, watch, computed, ref,
} from '@vue/composition-api';
import { mdiPlus, mdiPencil, mdiContentSave } from '@mdi/js';
import { orgBranches } from '@/helpers/common-data';

import libaryModule from '@/store/modules/library';
import profileModule from '@/store/modules/profile';
import router from '@/router';

const vectorIcons = { mdiPlus, mdiPencil, mdiContentSave };

export default {
  props: {
    currentEditBook: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit, root }) {
    const { currentUserProfile } = profileModule();
    const { libraryCategories } = libaryModule();
    const branches = computed(() => orgBranches.filter((b) => b.value !== 'vilkogu'));
    // eslint-disable-next-line no-underscore-dangle
    const orgBranch = computed(() => root._route.query.branch || '');
    const filteredLibraryCategories = computed(() => {
      if (!libraryCategories) return [];
      return libraryCategories.value.filter((cat) => cat.branch === orgBranch.value);
    });

    const state = reactive({
      valid: true,
      formData: {
        name: '',
        description: '',
        author: '',
        published: null,
        categoryId: null,
        branch: '',
        count: 1,
      },
    });
    const onInputChange = (field, value) => {
      // eslint-disable-next-line no-underscore-dangle
      router.replace({ query: { ...root._route.query, [field]: value } });
    };
    const currentEditBook = computed(() => props.currentEditBook);
    watch(currentEditBook, (val) => {
      state.formData = { ...val };
    });
    watch(currentUserProfile, (val) => {
      if (!state.formData.branch && val) {
        const availableOptions = ['tartu', 'tallinn'];
        if (availableOptions.includes(val.current_branch)) {
          state.formData.branch = val.current_branch;
        } else if (availableOptions.includes(val.org_branch)) {
          state.formData.branch = val.org_branch;
        }
        onInputChange('branch', state.formData.branch);
      }
    });
    const form = ref(null);
    const onSaveBook = async () => {
      await form.value.validate();
      if (!state.valid) return;
      emit('on-book-save', state.formData);
    };

    watch(orgBranch, () => {
      onInputChange('categoryId', '');
    });
    return {
      ...toRefs(state),
      ...vectorIcons,
      form,
      onSaveBook,
      libraryCategories,
      branches,
      currentUserProfile,
      filteredLibraryCategories,
      onInputChange,
    };
  },
};
</script>
