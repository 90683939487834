import {
  mdiFileDocumentOutline, mdiFolderZipOutline, mdiXml, mdiLanguageHtml5, mdiNodejs,
  mdiCodeJson, mdiLanguageMarkdown, mdiFilePdf, mdiFileExcel, mdiFileTable, mdiFileWord,
  mdiFileDelimited, mdiFilePowerpoint, mdiFileImage, mdiFileVideo, mdiFileMusic,
} from '@mdi/js';

export default {
  // common
  txt: {
    color: 'pohjala_blue',
    icon: mdiFileDocumentOutline,
  },
  zip: {
    color: 'lime',
    icon: mdiFolderZipOutline,
  },
  // techical
  xml: {
    color: 'cyan',
    icon: mdiXml,
  },
  htm: {
    color: 'pohjala_blue',
    icon: mdiLanguageHtml5,
  },
  html: {
    color: 'pohjala_blue',
    icon: mdiLanguageHtml5,
  },
  js: {
    color: 'pohjala_blue',
    icon: mdiNodejs,
  },
  json: {
    color: 'pohjala_blue',
    icon: mdiCodeJson,
  },
  md: {
    color: 'pohjala_blue',
    icon: mdiLanguageMarkdown,
  },

  // documents and office
  pdf: {
    color: 'red',
    icon: mdiFilePdf,
  },
  xls: {
    color: 'green',
    icon: mdiFileExcel,
  },
  xlsx: {
    color: 'green',
    icon: mdiFileExcel,
  },
  numbers: {
    color: 'green',
    icon: mdiFileTable,
  },
  doc: {
    color: 'pohjala_blue',
    icon: mdiFileWord,
  },
  docx: {
    color: 'pohjala_blue',
    icon: mdiFileWord,
  },
  rtx: {
    color: 'pohjala_blue',
    icon: mdiFileWord,
  },
  csv: {
    color: 'green',
    icon: mdiFileDelimited,
  },
  ppt: {
    color: 'red',
    icon: mdiFilePowerpoint,
  },
  pptx: {
    color: 'red',
    icon: mdiFilePowerpoint,
  },

  // media
  gif: {
    color: 'deep-orange',
    icon: mdiFileImage,
  },
  png: {
    color: 'deep-orange',
    icon: mdiFileImage,
  },
  jpg: {
    color: 'deep-orange',
    icon: mdiFileImage,
  },
  jpeg: {
    color: 'deep-orange',
    icon: mdiFileImage,
  },
  jpe: {
    color: 'deep-orange',
    icon: mdiFileImage,
  },
  svg: {
    color: 'deep-orange',
    icon: mdiFileImage,
  },

  wmv: {
    color: 'deep-orange',
    icon: mdiFileVideo,
  },
  flv: {
    color: 'deep-orange',
    icon: mdiFileVideo,
  },
  avi: {
    color: 'deep-orange',
    icon: mdiFileVideo,
  },
  mpg: {
    color: 'deep-orange',
    icon: mdiFileVideo,
  },
  mp4: {
    color: 'deep-orange',
    icon: mdiFileVideo,
  },
  mov: {
    color: 'deep-orange',
    icon: mdiFileVideo,
  },

  wav: {
    color: 'deep-orange',
    icon: mdiFileMusic,
  },
  mp3: {
    color: 'deep-orange',
    icon: mdiFileMusic,
  },
  ogg: {
    color: 'deep-orange',
    icon: mdiFileMusic,
  },
};
