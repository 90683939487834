import { toRefs, reactive } from '@vue/composition-api';
import firebase from 'firebase/compat/app';
// Required for side-effects
import 'firebase/compat/firestore';

/**
 *
 * @param { String } collectionName name of the desired collection
 * @param { object } queryOptions
 * @param { boolean | undefined } queryOptions.onMounted if true run query on mount
 * @param { string | undefined } queryOptions.query query string, see firebase documentation
 * @param { string | undefined } queryOptions.orderBy order results,  string, see firebase documentation
 * @param { number | undefined } queryOptions.limit number of object to return,  string, see firebase documentation
 */
export default function (target, collectionName, queryOptions) {
  const state = reactive({
    // error if one happens
    error: null,
    // the results of the query
    collectionData: [],
    // if the query is loading or ot
    loading: false,
  });

  // get the database
  const db = firebase.firestore();

  const isObject = (val) => Object.prototype.toString.call(val) === '[object Object]';
  const normalize = (snapshot, options) => {
    const value = snapshot.doc ? snapshot.doc.data() : snapshot.data();
    const out = isObject(value) ? value : { '.value': value };
    Object.defineProperty(out, options.keyName, {
      value: snapshot.doc ? snapshot.doc.id : snapshot.id,
      enumerable: options.enumerable,
    });
    return out;
  };
  const defaultOptions = {
    keyName: 'id',
    enumerable: true,
  };

  function bindCollection({ query, orderBy, limit } = queryOptions) {
    let theQuery = query ? db.collection(collectionName).where(query) : db.collection(collectionName);

    theQuery = limit ? theQuery.limit(limit) : theQuery;
    theQuery = orderBy ? theQuery.orderBy(orderBy) : theQuery;

    theQuery.onSnapshot(
      (doc) => {
        doc.docChanges().forEach(
          (snapshot) => {
            switch (snapshot.type) {
              case 'added':
                target.splice(snapshot.newIndex, 0, normalize(snapshot, defaultOptions));
                break;
              case 'removed':
                target.splice(snapshot.oldIndex, 1);
                break;
              case 'modified':
                if (snapshot.oldIndex !== snapshot.newIndex) {
                  target.splice(snapshot.oldIndex, 1);
                  target.splice(snapshot.newIndex, 0, normalize(snapshot, defaultOptions));
                } else {
                  target.splice(snapshot.newIndex, 1, normalize(snapshot, defaultOptions));
                }
                break;
              default:
                break;
            }
          },
          (error) => {
            state.error = error;
          },
        );
        return target;
      },
      (error) => {
        state.error = error;
      },
    );
  }
  return {
    ...toRefs(state),
    bindCollection,
  };
}
