import firebase from '@/firebaseinit';
import { state, actions } from '@/store/modules/auth';

export default async (to, from, next) => {
  if (to && to.name === 'signout') {
    await actions.signUserOut();
  }
  const user = await firebase.getCurrentUser();
  if (user) {
    state.user = user;
    if (to.meta.claims.includes('all')) return next();

    const tokenRes = await user.getIdTokenResult();
    const { claims } = tokenRes;
    if (to.meta.claims.some((claim) => !!claims && claim in claims && claims[claim] === true)) {
      return next();
    }

    return next();
  }
  // remember the route we were trying to enter but were not logged-in
  state.prevRoute = to;
  return next('/signin');
};
