<template>
  <v-card
    v-if="uploadQueue.length"
    class="upload-card"
  >
    <v-toolbar
      dense
      class="pohjala_blue white--text pr-2"
    >
      <span>Üleslaadimine {{ completeUploads.length }} / {{ uploadQueue.length }} valmis</span>
      <v-spacer />

      <v-btn
        small
        icon
        color="white"
        @click="minimized = !minimized"
      >
        <v-icon>
          {{ minimized ? mdiWindowMaximize : mdiWindowMinimize }}
        </v-icon>
      </v-btn>
      <v-btn
        :disabled="completeUploads.length !== uploadQueue.length"
        :dark="completeUploads.length !== uploadQueue.length"
        small
        icon
        color="white"
        @click="clearUploadQueue"
      >
        <v-icon>
          {{ mdiWindowClose }}
        </v-icon>
      </v-btn>
    </v-toolbar>
    <div
      v-show="!minimized"
      class="expanded-content"
    >
      <v-row
        v-for="(queueItem, index) in uploadQueue"
        :key="index"
        no-gutters
        align="center"
      >
        <v-col class="px-3">
          <span class="text-caption font-weight-medium">
            {{ queueItem.name }}
          </span>
        </v-col>
        <v-col
          v-if="queueItem.status === 'started'"
          class="shrink px-3"
        >
          <span class="text-caption font-weight-medium">Ootel</span>
        </v-col>
        <template v-else-if="queueItem.status === 'in_progress'">
          <v-col>
            <v-progress-linear :value="queueItem.percentage" />
          </v-col>
          <v-col class="shrink px-3">
            <span class="text-caption font-weight-medium"> {{ queueItem.percentage }}% </span>
          </v-col>
        </template>
        <v-col
          v-else-if="queueItem.status === 'complete'"
          class="shrink px-3"
        >
          <span class="success--text text-caption font-weight-medium">Valmis</span>
        </v-col>
        <v-col
          v-else-if="queueItem.status === 'failed'"
          class="shrink px-3"
        >
          <span class="error--text text-caption font-weight-medium">Ebaõnnestus</span>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>
<script>
import { computed, ref } from '@vue/composition-api';
import { mdiWindowMaximize, mdiWindowMinimize, mdiWindowClose } from '@mdi/js';
import storageModule from '@/store/modules/storage';

const vectorIcons = {
  mdiWindowMaximize,
  mdiWindowMinimize,
  mdiWindowClose,
};
export default {
  setup() {
    const minimized = ref(false);
    const { uploadQueue, clearUploadQueue } = storageModule();
    const completeUploads = computed(() => uploadQueue.value.filter((queueItem) => queueItem.status === 'complete'));
    return {
      uploadQueue, clearUploadQueue, completeUploads, minimized, ...vectorIcons,
    };
  },
};
</script>
<style scoped lang="scss">
.upload-card {
    width: 500px;
    position: fixed;
    bottom: 0;
    right: 20px;
    .expanded-content {
        min-height: 280px;
        max-height: 280px;
        overflow: auto;
    }
}
</style>
