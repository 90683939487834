import {
  toRefs, reactive, onMounted, isRef,
} from '@vue/composition-api';
import firebase from 'firebase/compat/app';
// Required for side-effects
import 'firebase/compat/firestore';

/**
 *
 * @param { String } collectionName name of the desired collection
 * @param { object } queryOptions
 * @param { boolean | undefined } queryOptions.onMounted if true run query on mount
 * @param { string | undefined } queryOptions.documentId query string, see firebase documentation
 */
export default function (_collectionName, queryOptions = {}) {
  const state = reactive({
    // error if one happens
    error: null,
    // the results of the query
    documentData: {},
    // if the query is loading or ot
    loading: false,
  });

  // get the database
  const db = firebase.firestore();

  /**
     * there is the option to load the query when the component
     * is mounted, you need to set the option in the `queryOptions`
     * params that you pass in
     *
     */

  const deleteDocument = async (_documentId) => {
    const collectionName = isRef(_collectionName) ? _collectionName.value : _collectionName;
    state.loading = true;
    state.error = null;
    try {
      await db.collection(collectionName)
        .doc(_documentId)
        .delete();
      console.log('Document successfully deleted!');
      state.error = null;
      state.documentData = null;
    } catch (error) {
      console.error('Error removing document: ', error);
      state.error = error;
      state.documentData = null;
    } finally {
      state.loading = false;
    }
  };

  const createDocument = async (_documentData) => {
    const collectionName = isRef(_collectionName) ? _collectionName.value : _collectionName;
    state.loading = true;
    state.error = null;
    try {
      const docRef = await db.collection(collectionName).add({
        ..._documentData,
        createdOn: firebase.firestore.FieldValue.serverTimestamp(),
      });
      state.error = null;
      state.documentData.id = docRef.id;
    } catch (error) {
      // The document probably doesn't exist.
      console.error('Error createDocument: ', error);
      state.error = error;
      state.documentData = null;
    } finally {
      state.loading = false;
    }
  };

  const updateDocument = async (_documentData) => {
    const collectionName = isRef(_collectionName) ? _collectionName.value : _collectionName;
    state.loading = true;
    state.error = null;

    const data = { ..._documentData };
    delete data.id;
    try {
      await db.collection(collectionName)
        .doc(_documentData.id)
        .update({
          ...data,
          updatedOn: firebase.firestore.FieldValue.serverTimestamp(),
        });
      state.error = null;
      state.documentData = null;
    } catch (error) {
      // The document probably doesn't exist.
      console.error('Error updating document: ', error);
      state.error = error;
      state.documentData = null;
    } finally {
      state.loading = false;
    }
  };

  const setDocument = async (_documentData) => {
    const collectionName = isRef(_collectionName) ? _collectionName.value : _collectionName;
    state.loading = true;
    state.error = null;

    const data = { ..._documentData };
    delete data.id;
    try {
      await db
        .collection(collectionName)
        .doc(String(_documentData.id))
        .set(
          {
            ...data,
            updatedOn: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true },
        );

      state.error = null;
      state.documentData = null;
    } catch (error) {
      state.error = error;
      state.documentData = null;
    } finally {
      state.loading = false;
    }
  };

  /**
     *
     * @param { object } queryOptions
     * @param { boolean | undefined } queryOptions.onMounted
     * @param { string | undefined } queryOptions.documentId
     */
  const getDocument = async (documentId) => {
    const collectionName = isRef(_collectionName) ? _collectionName.value : _collectionName;
    state.loading = true;
    state.error = null;
    try {
      const doc = await db.collection(collectionName).doc(documentId).get();
      if (doc.exists) {
        console.log('Document data:', doc.data());
        state.documentData = { id: doc.id, ...doc.data() };
        state.error = null;
      } else {
        // doc.data() will be undefined in this case
        console.log(`No such document!: ${documentId}`);
        state.documentData = null;
        state.error = null;
      }
    } catch (error) {
      console.log('Error getDocuent: ', error);
      state.error = error;
    } finally {
      state.loading = false;
    }
    return state.documentData || null;
  };
  if (queryOptions && queryOptions.onMounted) {
    onMounted(() => {
      getDocument(queryOptions.documentId);
    });
  }
  return {
    ...toRefs(state),
    getDocument,
    createDocument,
    updateDocument,
    deleteDocument,
    setDocument,
  };
}
