<template>
  <v-container class="fill-height fluid align-start">
    <v-row
      no-gutters
      class="mt-10"
    >
      <v-col
        class="my-2"
        cols="12"
      >
        <v-tabs
          v-model="activeTab"
          slider-color="pohjala_blue"
          color="pohjala_blue"
          background-color="transparent"
          show-arrows
        >
          <v-tab
            v-for="(list, index) in tabs"
            :key="index"
            ripple
          >
            <span class="text-body-2">{{ list.label }}</span>
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col
        class="my-2"
        cols="12"
      >
        <v-data-table
          :items="filteredGroups"
          :headers="headers"
          :items-per-page="-1"
          hide-default-footer
          hide-default-header
          dense
          class="elevation-2"
        >
          <template #body="{ items }">
            <tbody>
              <tr
                v-for="item in items"
                :key="item.name"
                @click="onGroupRowClick(item)"
              >
                <td class="font-weight-medium">
                  {{ item.name }}
                </td>
                <td>
                  {{ item.email }}
                </td>
                <td>
                  {{ item.description }}
                </td>
                <td class="flex-nowrap">
                  <span class="mr-1">{{ item.directMembersCount }}</span>
                  <v-icon
                    color="blue darken-4"
                    size="18"
                    class="mb-1"
                  >
                    people
                  </v-icon>
                </td>
                <td class="text-end">
                  <v-btn
                    icon
                    @click.stop="onJoinGroupClick(item)"
                  >
                    <v-icon color="blue darken-3">
                      {{ mdiEmailPlus }}
                    </v-icon>
                  </v-btn>
                  <template v-if="myListsActive">
                    <v-btn
                      icon
                      @click.stop="onLeaveGroupClick(item)"
                    >
                      <v-icon color="red darken-3">
                        {{ mdiEmailMinus }}
                      </v-icon>
                    </v-btn>
                  </template>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { reactive, toRefs, computed } from '@vue/composition-api';
import { mdiEmailPlus, mdiEmailMinus } from '@mdi/js';
import groupModule from '@/store/modules/group';
import dialogModule from '@/store/modules/dialog';
import profileModule from '@/store/modules/profile';
import router from '@/router';

const vectorIcons = {
  mdiEmailPlus,
  mdiEmailMinus,
};
export default {
  props: {},
  setup() {
    const { setDialogOptions, openDialog } = dialogModule();
    const { groups } = groupModule();
    const { currentUserProfile } = profileModule();
    const state = reactive({
      headers: [
        {
          text: 'Nimi',
          align: 'start',
          value: 'name',
        },
        {
          text: 'E-mail',
          value: 'email',
        },
        {
          text: 'Kirjeldus',
          value: 'description',
        },
        {
          text: 'Liikmete arv',
          value: 'directMembersCount',
          align: 'end',
        },
      ],
      activeTab: 0,
      tabs: [
        {
          label: 'Üldlistid',
          name: 'yldlist',
        },
        {
          label: 'Tallinna koondis',
          name: 'tallinn',
        },
        {
          label: 'Tartu koondis',
          name: 'tartu',
        },
        {
          label: 'Vilistlaskogu',
          name: 'vilistlaskogu',
        },
        {
          label: 'Muud',
          name: 'muud',
        },
        {
          label: 'Minu poestiloendid',
          name: 'my_lists',
        },
      ],
    });

    const myListsActive = computed(() => state.tabs[state.activeTab].name === 'my_lists');
    const onGroupRowClick = (item) => {
      router.push({
        name: 'groupview',
        params: {
          groupId: item.id,
        },
      }).catch(() => {});
    };
    const onJoinGroupClick = (item) => {
      setDialogOptions({
        component: 'GroupJoinForm',
        payload: {
          groupId: item.id,
        },
      });
      openDialog();
    };
    const onLeaveGroupClick = (item) => {
      setDialogOptions({
        component: 'GroupLeaveForm',
        payload: {
          groupId: item.id,
        },
      });
      openDialog();
    };
    const currentGroupMemberships = computed(() => {
      if (
        currentUserProfile.value
                && currentUserProfile.value.group_memberships
                && currentUserProfile.value.group_memberships
      ) {
        return currentUserProfile.value.group_memberships;
      }
      return {};
    });
    const filteredGroups = computed(() => {
      if (!groups.value) {
        return [];
      } if (state.tabs[state.activeTab].name === 'yldlist') {
        return groups.value.filter(
          (group) => group.name.toLowerCase().endsWith('tallinna koondis')
                        || group.name.toLowerCase().endsWith('tartu koondis')
                        || group.name.toLowerCase().endsWith('vilistlaskogu')
                        || group.name.toLowerCase().endsWith('seltsi üldlist'),
        );
      } if (state.tabs[state.activeTab].name === 'muud') {
        return groups.value.filter((group) => state.tabs.every((tab) => !group.name.toLowerCase().includes(tab.name.toLowerCase())));
      } if (myListsActive.value) {
        return groups.value.filter(
          (group) => currentGroupMemberships.value[group.id] && currentGroupMemberships.value[group.id].length,
        );
      }
      return groups.value.filter((group) => group.name.toLowerCase().includes(state.tabs[state.activeTab].name.toLowerCase()));
    });
    return {
      ...toRefs(state),
      ...vectorIcons,
      groups,
      currentGroupMemberships,
      filteredGroups,
      myListsActive,
      onGroupRowClick,
      onJoinGroupClick,
      onLeaveGroupClick,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
