<template>
  <GenericEditableInfoBlock
    :field-components="fields"
    :doc-data="userProfile"
    doc-type="user_profiles"
    card-title="Kontakt"
    :edit-mode="editMode"
    form-name="contact"
    :can-edit="canEdit"
    @update:editMode="onEditModeUpdate"
  />
</template>
<script>
import { reactive, toRefs } from '@vue/composition-api';
import {
  mdiHome, mdiPhone, mdiPencil, mdiSchool, mdiContentSave, mdiEmail,
} from '@mdi/js';
import { createField } from '../../helpers/form-helpers';

import GenericEditableInfoBlock from './GenericEditableInfoBlock.vue';

const vectorIcons = {
  mdiPencil,
  mdiSchool,
  mdiContentSave,
};
export default {
  components: {
    GenericEditableInfoBlock,
  },
  props: {
    userProfile: {
      type: Object,
      default: () => {},
    },
    editMode: {
      type: String,
      default: '',
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      fields: [
        createField({
          key: 'primary_email',
          component: 'v-select',
          icon: mdiEmail,
          props: {
            label: 'Email',
            // required: true,
            // rules: requiredRules("Haridustase"),
            items: props.userProfile.email_addresses,
          },
          colProps: { cols: 12, sm: 12 },
        }),
        createField({
          key: 'phone',
          icon: mdiPhone,
          colProps: { cols: 12, sm: 12 },
          props: { label: 'Telefon', disabled: true, hint: 'asd' },
        }),
        createField({
          key: 'place_of_residence',
          icon: mdiHome,
          colProps: { cols: 12, sm: 12 },
          props: { label: 'Elukoht' },
        }),
      ],
      profileUpdates: {},
    });

    const onEditModeUpdate = (val) => {
      emit('update:editMode', val);
    };

    return {
      ...toRefs(state),
      ...vectorIcons,
      onEditModeUpdate,
    };
  },
};
</script>
