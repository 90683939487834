<template>
  <GenericEditableInfoBlock
    :field-components="fields"
    :doc-data="userProfile"
    doc-type="user_profiles"
    card-title="Personaalne"
    :edit-mode="editMode"
    :can-edit="canEdit"
    form-name="personal"
    @update:editMode="onEditModeUpdate"
  />
</template>
<script>
import { reactive, toRefs } from '@vue/composition-api';
import {
  mdiCandle,
  mdiHomePlus,
  mdiHomeMinus,
  mdiCakeVariant,
  mdiJudaism,
  mdiPencil,
  mdiSchool,
  mdiContentSave,
} from '@mdi/js';
import { createField } from '../../helpers/form-helpers';

import GenericEditableInfoBlock from './GenericEditableInfoBlock.vue';
import { formatShortDate } from '../../helpers/date-helpers';

const vectorIcons = {
  mdiPencil,
  mdiSchool,
  mdiContentSave,
};
export default {
  components: {
    GenericEditableInfoBlock,
  },
  props: {
    userProfile: {
      type: Object,
      default: () => {},
    },
    editMode: {
      type: String,
      default: '',
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      fields: [
        createField({
          key: 'first_name',
          props: { label: 'Eesnimi' },
          colProps: { cols: 12, sm: 12 },
          hidden: true,
        }),
        createField({
          key: 'last_name',
          props: { label: 'Perekonnanimi' },
          colProps: { cols: 12, sm: 12 },
          hidden: true,
        }),

        createField({
          key: 'date_of_birth',
          component: 'date-select-field',
          icon: mdiCakeVariant,
          colProps: { cols: 12, sm: 12 },
          props: { label: 'Sünniaeg', type: 'DATE', clearable: true },
          formatFunc: (val) => formatShortDate(val, 'DATE'),
        }),
        createField({
          key: 'place_of_birth',
          icon: mdiHomePlus,
          colProps: { cols: 12, sm: 12 },
          props: { label: 'Sünnikoht' },
        }),
        createField({
          key: 'date_of_death',
          component: 'date-select-field',
          icon: mdiCandle,
          colProps: { cols: 12, sm: 12 },
          props: { label: 'Surmaaeg', type: 'DATE', clearable: true },
          formatFunc: (val) => formatShortDate(val, 'DATE'),
        }),
        createField({
          key: 'place_of_death',
          icon: mdiHomeMinus,
          colProps: { cols: 12, sm: 12 },
          props: { label: 'Surmakoht' },
        }),
        createField({
          key: 'religion',
          icon: mdiJudaism,
          colProps: { cols: 12, sm: 12 },
          props: { label: 'Religioon' },
        }),
      ],
      profileUpdates: {},
    });

    const onEditModeUpdate = (val) => {
      emit('update:editMode', val);
    };

    return {
      ...toRefs(state),
      ...vectorIcons,
      onEditModeUpdate,
    };
  },
};
</script>
