<template>
  <v-row
    class="mx-1 form-container"
    no-gutters
  >
    <v-col
      class="py-2"
      cols="12"
    >
      <ValidationObserver v-slot="{ handleSubmit }">
        <v-form @submit.prevent="handleSubmit(onSignin)">
          <ValidationProvider
            v-slot="{ errors }"
            name="E-mail"
            rules="required|email"
            mode="eager"
          >
            <v-text-field
              id="email"
              v-model="email"
              :error-messages="errors"
              name="email"
              label="E-posti aadress"
              type="email"
              required
              dark
              flat
              color="white"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="Password"
            rules="required"
            mode="eager"
          >
            <v-text-field
              id="password"
              v-model="password"
              :append-icon="show_password ? 'visibility_off' : 'visibility'"
              :type="show_password ? 'text' : 'password'"
              :error-messages="errors"
              name="password"
              label="Salasõna"
              required
              dark
              flat
              color="white"
              @click:append="show_password = !show_password"
            />
          </ValidationProvider>
          <v-btn
            :disabled="loading"
            :loading="loading"
            :large="$vuetify.breakpoint.xsOnly"
            type="submit"
            block
            outlined
            dark
            class="mt-3"
          >
            Sisene
            <v-icon right>
              lock_open
            </v-icon>
            <span
              slot="loader"
              class="custom-loader"
            >
              <v-icon light>
                cached
              </v-icon>
            </span>
          </v-btn>
          <v-btn
            dark
            block
            small
            text
            class="my-3 text-none"
            @click="onForgotClick"
          >
            Unustasid parooli?
          </v-btn>
          <div
            class="red--text text-left text-body-2 error-message"
          >
            {{ authErrorMessage }}
          </div>
        </v-form>
      </ValidationObserver>
    </v-col>
  </v-row>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { computed, reactive, toRefs } from '@vue/composition-api';
import authModule from '../../../store/modules/auth';
import router from '../../../router';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  setup() {
    const { signUserIn, prevRoute } = authModule();
    const state = reactive({
      email: '',
      password: '',
      show_password: false,
      auth_error: '',
      loading: false,
    });
    const authErrorMessage = computed(() => {
      if (state.auth_error) {
        if (
          state.auth_error.code === 'auth/wrong-password'
                    || state.auth_error.code === 'auth/user-not-found'
                    || state.auth_error.code === 'auth/invalid-credential'
        ) {
          return 'Vale parool või kasutajat ei leitud!';
        } if (state.auth_error.code === 'auth/too-many-requests') {
          return 'Liiga palju ebaõnnestunud sisselogimiskatseid. Palun proovi hiljem uuesti.';
        }
        return 'Süsteemi viga!';
      }
      return '';
    });
    const onForgotClick = () => {
      router.replace({
        name: 'forgot_password',
      });
      state.auth_error = '';
    };
    const onSignin = async () => {
      state.loading = true;
      state.auth_error = '';
      try {
        await signUserIn({
          email: state.email,
          password: state.password,
        });
      } catch (err) {
        state.auth_error = err;
      }
      state.loading = false;
    };
    return {
      ...toRefs(state),
      authErrorMessage,
      onSignin,
      onForgotClick,
      prevRoute,
    };
  },
};
</script>
<style lang="scss" scoped>
.form-container {
    max-width: 300px
}
.error-message {
    height: 20px;
    width: 100%;
    display: block
}
</style>
