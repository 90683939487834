<template>
  <GenericEditableInfoBlock
    :field-components="fields"
    :doc-data="userProfile"
    doc-type="user_profiles"
    card-title="Liikmeinfo"
    :edit-mode="editMode"
    form-name="membership"
    :can-edit="canEdit"
    @update:editMode="onEditModeUpdate"
  />
</template>
<script>
import { reactive, toRefs } from '@vue/composition-api';
import {
  mdiHomeCity,
  mdiHomeCityOutline,
  mdiAccountChildOutline,
  mdiAccountPlus,
  mdiPencil,
  mdiSchool,
  mdiContentSave,
} from '@mdi/js';
import { createField } from '../../helpers/form-helpers';
import { orgTitles, orgBranches } from '../../helpers/common-data';
import GenericEditableInfoBlock from './GenericEditableInfoBlock.vue';
import { formatCoetusDate } from '../../helpers/date-helpers';

import profileModule from '../../store/modules/profile';

const vectorIcons = {
  mdiPencil,
  mdiSchool,
  mdiContentSave,
};
export default {
  components: {
    GenericEditableInfoBlock,
  },
  props: {
    userProfile: {
      type: Object,
      default: () => {},
    },
    editMode: {
      type: String,
      default: '',
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { profiles } = profileModule();
    const state = reactive({
      fields: [
        createField({
          key: 'org_title',
          component: 'v-select',
          props: {
            label: 'Tiitel',
            items: Object.values(orgTitles),
            'item-text': 'label',
            'item-value': 'value',
          },
          colProps: { cols: 12, sm: 12 },
          hidden: true,
        }),
        createField({
          key: 'current_branch',
          component: 'v-select',
          icon: mdiHomeCity,
          colProps: { cols: 12, sm: 12 },
          props: {
            label: 'Praegune koondis',
            items: orgBranches,
            'item-text': 'name',
            'item-value': 'value',
          },
        }),
        createField({
          key: 'date_of_coetus',
          component: 'date-select-field',
          icon: mdiAccountPlus,
          colProps: { cols: 12, sm: 12 },
          props: { label: 'Coetus', type: 'DATE' },
          formatFunc: (val) => formatCoetusDate(val, true),
        }),
        createField({
          key: 'org_branch',
          component: 'v-select',
          icon: mdiHomeCityOutline,
          colProps: { cols: 12, sm: 12 },
          props: {
            label: 'Liitumiskoondis',
            items: orgBranches.filter((b) => b.value !== 'vilkogu'),
            'item-text': 'name',
            'item-value': 'value',
          },
        }),
        createField({
          key: 'org_parent',
          component: 'v-autocomplete',
          icon: mdiAccountChildOutline,
          colProps: { cols: 12, sm: 12 },
          props: {
            label: 'Akadeemiline vanem',
            items: profiles.value,
            'item-text': (i) => `${(i && i.org_title && orgTitles[i.org_title] && orgTitles[i.org_title].label) || '??'} ${i.first_name} ${i.last_name}`,
            'item-value': 'id',
          },
        }),
      ],
      profileUpdates: {},
    });

    const onEditModeUpdate = (val) => {
      emit('update:editMode', val);
    };

    return {
      ...toRefs(state),
      ...vectorIcons,
      onEditModeUpdate,
    };
  },
};
</script>
