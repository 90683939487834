import Vue from 'vue';
import Router from 'vue-router';
import {
//   mdiFamilyTree,
  mdiDatabaseCogOutline, mdiAccountMultiplePlus, mdiBookshelf,
  mdiEmail, mdiWikipedia, mdiCamera, mdiFolderOpen, mdiAccountMultiple, mdiAccount,
  mdiBriefcase, mdiEmoticonDevilOutline, mdiGlassMugVariant, mdiLogoutVariant, mdiViewDashboard,
} from '@mdi/js';

import Main from '@/components/Main.vue';
import ProfilesList from '@/components/pages/Profiles/index.vue';
import UserProfile from '@/components/pages/Profiles/UserProfile.vue';

import OfficialsList from '@/components/pages/Officials/index.vue';

import Entry from '@/components/pages/SignIn/index.vue';
import SignInForm from '@/components/pages/SignIn/SignInForm.vue';
import ForgotForm from '@/components/pages/SignIn/ForgotPasswordForm.vue';
import ForgotSuccess from '@/components/pages/SignIn/ForgotPasswordSuccess.vue';

import AdminView from '@/components/pages/Admin/index.vue';
import AdminAuthUsersList from '@/components/pages/Admin/AdminAuthUsersList.vue';
import AdminDatabaseJsonTool from '@/components/pages/Admin/AdminDatabaseJSONTool.vue';

import DocumentManager from '@/components/pages/DocumentManager/index.vue';

import PhotoGallery from '@/components/pages/PhotoGallery/index.vue';

import GroupsList from '@/components/pages/GoogleGroups/index.vue';
import GroupView from '@/components/pages/GoogleGroups/GroupView.vue';
import GroupArchiveList from '@/components/pages/GoogleGroups/GroupArchiveList.vue';
import MailThreadView from '@/components/pages/GoogleGroups/MailThreadView.vue';

// import FamilyTree from '@/components/pages/FamilyTree.vue';

import Library from '@/components/pages/Library/index.vue';
import BarSystem from '@/components/pages/BarSystem/index.vue';
import Dashboard from '@/components/pages/Dashboard/index.vue';

import AuthGuard from './auth_guard';

Vue.use(Router);

const routes = [
  {
    path: '/',
    name: 'Main',
    meta: {
      claims: ['all'],
    },
    component: Main,
    beforeEnter: AuthGuard,
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        beforeEnter: AuthGuard,
        meta: {
          icon: mdiViewDashboard,
          title: 'Armatuurlaud',
          claims: ['all'], // all
          breadcrumb: [
            {
              name: 'Armatuurlaud',
            },
          ],
          menus: ['main'],
        },
      },
      {
        path: '#',
        beforeEnter: AuthGuard,
        meta: {
          icon: mdiWikipedia,
          title: 'Vikipeedia',
          action: () => window.open('https://viki.pohjala.ee', '_blank'),
          claims: ['all'], // all
          breadcrumb: [],
          menus: ['main'],
        },
      },
      {
        path: 'groups',
        name: 'groupslist',
        component: GroupsList,
        beforeEnter: AuthGuard,
        meta: {
          icon: mdiEmail,
          title: 'Postiloendid',
          claims: ['all'],
          breadcrumb: [
            {
              name: 'Postiloendid',
            },
          ],
          menus: ['main'],
        },
      },
      {
        path: '/photos',
        name: 'photos',
        component: PhotoGallery,
        beforeEnter: AuthGuard,
        meta: {
          icon: mdiCamera,
          title: 'Fotoalbum',
          claims: ['all'],
          breadcrumb: [],
          menus: ['main'],
        },
      },
      {
        path: '/photos*',
        component: PhotoGallery,
        beforeEnter: AuthGuard,
        meta: {
          icon: mdiCamera,
          title: 'Fotoalbum',
          claims: ['all'],
          breadcrumb: [],
        },
      },
      {
        path: '/failid',
        name: 'documents',
        component: DocumentManager,
        beforeEnter: AuthGuard,
        meta: {
          icon: mdiFolderOpen,
          title: 'Dokumendid',
          claims: ['all'],
          breadcrumb: [],
          menus: ['main'],
        },
      },
      {
        path: '/failid*',
        component: DocumentManager,
        beforeEnter: AuthGuard,
        meta: {
          icon: mdiFolderOpen,
          title: 'Dokumendid',
          claims: ['all'],
          breadcrumb: [],
        },
      },
      {
        path: '/docs*',
        component: DocumentManager,
        beforeEnter: AuthGuard,
        meta: {
          icon: mdiFolderOpen,
          title: 'Dokumendid',
          claims: ['all'],
          breadcrumb: [],
        },
      },
      {
        path: '/~*',
        component: DocumentManager,
        beforeEnter: AuthGuard,
        meta: {
          icon: mdiFolderOpen,
          title: 'Dokumendid',
          claims: ['all'],
          breadcrumb: [],
        },
      },
      {
        path: 'users',
        name: 'userslist',
        component: ProfilesList,
        beforeEnter: AuthGuard,
        meta: {
          icon: mdiAccountMultiple,
          title: 'Liikmed',
          claims: ['superadmin', 'admin'], // all
          breadcrumb: [
            {
              name: 'Kasutajaloend',
            },
          ],
          menus: ['main'],
        },
      },
      {
        path: 'library',
        name: 'library',
        component: Library,
        beforeEnter: AuthGuard,
        meta: {
          icon: mdiBookshelf,
          title: 'Raamatukogu',
          claims: ['all'],
          breadcrumb: [
            {
              name: 'Raamatukogu',
            },
          ],
          menus: ['main'],
        },
      },
      {
        path: 'bar',
        name: 'bar',
        component: BarSystem,
        beforeEnter: AuthGuard,
        meta: {
          icon: mdiGlassMugVariant,
          title: 'Baar',
          claims: ['superadmin', 'admin'],
          breadcrumb: [
            {
              name: 'Baar',
            },
          ],
          menus: ['main'],
        },
      },
      //   {
      //     path: 'familytree',
      //     name: 'faimilytree',
      //     component: FamilyTree,
      //     beforeEnter: AuthGuard,
      //     meta: {
      //       icon: mdiFamilyTree,
      //       title: 'Sugupuu',
      //       claims: ['superadmin', 'admin'], // "all"
      //       breadcrumb: [
      //         {
      //           name: 'Sugupuu',
      //         },
      //       ],
      //       menus: ['main'],
      //     },
      //   },
      {
        path: 'officers',
        name: 'officerslist',
        component: OfficialsList,
        beforeEnter: AuthGuard,
        meta: {
          icon: mdiBriefcase,
          title: 'Ametikandjad',
          claims: ['all'],
          breadcrumb: [
            {
              name: 'Ametikandjad',
            },
          ],
          // menus: ["main"]
        },
      },
      {
        path: '/user/:id?',
        name: 'userprofile',
        component: UserProfile,
        beforeEnter: AuthGuard,
        meta: {
          icon: mdiAccount,
          title: 'Minu info',
          claims: ['all'],
          // menus: ["main"]
        },
      },
      {
        path: '/groups/:groupId',
        name: 'groupview',
        component: GroupView,
        beforeEnter: AuthGuard,
        meta: {
          claims: ['all'],
          breadcrumb: [],
        },
      },
      {
        path: '/groups/:groupId/archive',
        name: 'mailarchivelist',
        component: GroupArchiveList,
        beforeEnter: AuthGuard,
        meta: {
          claims: ['all'],
          breadcrumb: [],
        },
      },
      {
        path: '/groups/:groupId/archive/:threadId',
        name: 'threadmessages',
        component: MailThreadView,
        beforeEnter: AuthGuard,
        meta: {
          claims: ['all'],
          breadcrumb: [],
        },
      },
      {
        path: '/admin',
        name: 'adminview',
        component: AdminView,
        beforeEnter: AuthGuard,
        meta: {
          icon: mdiEmoticonDevilOutline,
          title: 'Admin',
          claims: ['superadmin', 'admin'],
          breadcrumb: [],
          menus: ['main'],
        },
      },
      {
        path: '/authlist',
        name: 'authlist',
        component: AdminAuthUsersList,
        beforeEnter: AuthGuard,
        meta: {
          icon: mdiAccountMultiplePlus,
          title: 'Kasutajate haldus (admin)',
          claims: ['superadmin', 'admin'],
          breadcrumb: [],
          menus: ['main'],
        },
      },
      {
        path: '/databasetool',
        name: 'databasetool',
        component: AdminDatabaseJsonTool,
        beforeEnter: AuthGuard,
        meta: {
          icon: mdiDatabaseCogOutline,
          title: 'Database tool',
          claims: ['superadmin'],
          breadcrumb: [],
          menus: ['main'],
        },
      },
      {
        path: '/signout',
        name: 'signout',
        beforeEnter: AuthGuard,
        meta: {
          icon: mdiLogoutVariant,
          title: 'Log out',
          claims: ['all'],
          breadcrumb: [],
          menus: ['main'],
        },
      },
    ],
  },
  {
    path: '/signin',
    meta: {
      claims: ['all'],
    },
    component: Entry,
    children: [
      {
        path: '',
        name: 'signin',
        meta: {
          claims: ['all'],
        },
        component: SignInForm,
      },
      {
        path: '/forgot',
        name: 'forgot_password',
        meta: {
          claims: ['all'],
        },
        component: ForgotForm,
      },
      {
        path: '/forgot/:email',
        name: 'reset_success',
        meta: {
          claims: ['all'],
        },
        component: ForgotSuccess,
      },
    ],
  },
];
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
