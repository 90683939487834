<template>
  <v-container class="fill-height fluid align-start">
    <v-row
      v-if="group"
      no-gutters
      justify="center"
      align="center"
    >
      <v-col
        class="my-2"
        cols="6"
      >
        <div class="text-h5 font-weight-medium pohjala_blue--text">
          {{ group.name }}
        </div>
        <div class="subheading">
          {{ group.email }}
        </div>
        <div>{{ group.description }}</div>
      </v-col>
      <v-col class="xs6 my-2">
        <v-row class="justify-end">
          <v-btn
            color="primary"
            dark
            @click="onJoinGroupClick($route.params.groupId)"
          >
            <v-icon left>
              {{ mdiEmailPlus }}
            </v-icon>
            Liitu
          </v-btn>
          <v-btn
            color="red darken-2 "
            dark
            text
            class="ml-1"
            @click="onLeaveGroupClick($route.params.groupId)"
          >
            <v-icon left>
              {{ mdiEmailMinus }}
            </v-icon>
            Lahku
          </v-btn>
        </v-row>
      </v-col>
      <v-col
        class="my-2"
        cols="12"
      >
        <v-tabs
          v-model="activeTab"
          slider-color="pohjala_blue"
          color="pohjala_blue"
          background-color="transparent"
          show-arrows
        >
          <v-tab
            v-for="(list, index) in tabs"
            :key="index"
            ripple
          >
            <span class="text-body-2">{{ list.label }}</span>
          </v-tab>
        </v-tabs>
      </v-col>
      <component
        :is="tabs[activeTab]['component']"
        :group-addresses="groupAddresses"
      />
    </v-row>
  </v-container>
</template>
<script>
import { computed, reactive, toRefs } from '@vue/composition-api';
import { mdiEmailPlus, mdiEmailMinus } from '@mdi/js';
import dialogModule from '@/store/modules/dialog';
import groupModule from '@/store/modules/group';
import GroupMembersList from './GroupMembersList.vue';
import GroupArchiveList from './GroupArchiveList.vue';

const vectorIcons = {
  mdiEmailPlus,
  mdiEmailMinus,
};
export default {
  components: {
    GroupMembersList,
    GroupArchiveList,
  },
  setup(props, ctx) {
    const { setDialogOptions, openDialog } = dialogModule();
    const { getGroup } = groupModule();
    // eslint-disable-next-line no-underscore-dangle
    const groupId = computed(() => ctx.root._route.params.groupId);
    const group = getGroup(groupId);

    const state = reactive({
      activeTab: 0,
      tabs: [
        {
          label: 'Arhiiv',
          name: 'arhiiv',
          component: GroupArchiveList,
        },
        {
          label: 'Liikmed',
          name: 'liikmed',
          component: GroupMembersList,
        },
      ],
    });

    const onJoinGroupClick = (id) => {
      setDialogOptions({
        component: 'GroupJoinForm',
        payload: {
          groupId: id,
        },
      });
      openDialog();
    };
    const onLeaveGroupClick = (id) => {
      setDialogOptions({
        component: 'GroupLeaveForm',
        payload: {
          groupId: id,
        },
      });
      openDialog();
    };
    const groupAddresses = computed(() => {
      let aliases = [];
      if (group.value.aliases) aliases = group.value.aliases;
      return [group.value.email, ...aliases];
    });
    return {
      ...toRefs(state),
      ...vectorIcons,
      group,
      onJoinGroupClick,
      onLeaveGroupClick,
      groupAddresses,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
