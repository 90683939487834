import {
  inject, provide, reactive, toRefs,
} from '@vue/composition-api';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import intersection from 'lodash/intersection';

export const state = reactive({
  results: [],
  queryResultsCache: [],
  loading: false,
});
export const actions = {
  async performSearch({ searchString, entityTypes = [], filters = [] }) {
    try {
      let searchIndexResults = {};
      if (searchString && searchString.length) {
        // normalize text by removing accented letters, dots and etc. and turn to lowercase
        const normalized = searchString
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');

        let parts = normalized.match(/\b(\w+)\b/g); // split by word

        parts = parts.filter((part) => part && part.length);

        for (let wordIndex = 0; wordIndex < parts.length; wordIndex += 1) {
          const currentWord = parts[wordIndex];
          let query = firebase.firestore().collection('search_index');
          if (wordIndex === 0) {
            if (parts.length > wordIndex + 1) {
              query = query.where('text', '==', currentWord);
            } else if (currentWord.length < 3) {
              query = query.where('text', '>=', currentWord).where('text', '<=', `${currentWord}\uf8ff`);
            } else {
              query = query.where('textSubstrings', 'array-contains', currentWord);
            }
          } else if (parts.length > wordIndex + 1) {
            query = query.where('text', '==', currentWord);
          } else if (entityTypes.length === 1) {
            // todo:: filter by ids gained from first request, change matching entities/uniqueids to array and use array-contains-any
            query = query.where('text', '>=', currentWord).where('text', '<=', `${currentWord}\uf8ff`);
            Object.entries(searchIndexResults).forEach(([entity, idsList]) => {
              let idsListClone = [...idsList];
              if (idsListClone.length > 10) idsListClone = idsListClone.slice(0, 9);
              query.where(`matchingEntities.${entity}`, 'array-contains-any', idsListClone);
            });
          }

          // todo:: order by textLength, backend rebuild indexes endpoint, add category in category dropdown, update library list on create/edit
          query.limit(10); // is 10 cause "in" query supports currently up to 10 values
          // eslint-disable-next-line no-await-in-loop
          const response = await query.get();
          // eslint-disable-next-line no-loop-func
          searchIndexResults = response.docs.reduce((map, doc) => {
            const mapClone = { ...map };
            const data = doc.data();

            entityTypes.forEach((entity) => {
              if (!mapClone[entity]) mapClone[entity] = [];
              if (data.matchingEntities && data.matchingEntities[entity]) {
                if (wordIndex === 0) {
                  mapClone[entity] = [...mapClone[entity], ...data.matchingEntities[entity]];
                } else {
                  mapClone[entity] = [
                    ...mapClone[entity],
                    ...intersection(searchIndexResults[entity], data.matchingEntities[entity]),
                    // ...intersection(mapClone[entity], data.matchingEntities[entity]),
                  ];
                }
              }
            });

            return mapClone;
          }, {});
        }
      }

      const queryList = [];
      entityTypes.forEach((entity) => {
        const filtersWithSearch = [...filters];
        if (searchString && searchString.length) {
          let resultIds = searchIndexResults[entity];
          if (!resultIds || resultIds.length === 0) {
            queryList.push(
              new Promise((resolve) => {
                resolve([]);
              }),
            );
            return;
          }
          if (resultIds.length > 10) resultIds = resultIds.slice(0, 9);
          if (resultIds.length === 0) queryList.push([]);

          const resultIdsAsStrings = resultIds.map((id) => String(id));
          filtersWithSearch.push([firebase.firestore.FieldPath.documentId(), 'in', resultIdsAsStrings]);
        }
        queryList.push(actions.performEntityQuery({ entity, filters: filtersWithSearch }));
      });
      const responses = await Promise.all(queryList);
      state.results = responses.flat();
    } catch (err) {
      console.log(err);
    }
  },
  async performEntityQuery({ entity, filters }) {
    let query = firebase.firestore().collection(entity);
    filters.forEach((f) => {
      if (f && f.length && f.length === 3 && !!f[2]) {
        query = query.where(...f);
      }
    });
    query = query.limit(20);
    const response = await query.get();
    const documents = response.docs.map((doc) => {
      const data = doc.data();
      const { id } = doc;
      return {
        id,
        ...data,
      };
    });
    return documents;
  },
};

export const key = Symbol('SearchStore');

export const provideSearch = () => provide(key, {
  ...toRefs(state),
  ...actions,
});

export default () => inject(key);
