<template>
  <v-card class="ma-3">
    <v-card-title class="pt-3 pb-0">
      <v-row no-gutters>
        <v-col class="pa-0 shrink">
          <span class="text-h6 text--primary text-no-wrap">{{ cardTitle }}</span>
        </v-col>
        <v-col
          v-if="canEdit"
          class="text-right pa-0"
        >
          <div v-if="editMode === formName">
            <v-btn
              small
              class="mx-1"
              color="primary"
              text
              @click="onCancelEdit"
            >
              Tühista
            </v-btn>
            <v-btn
              small
              class="mx-1"
              color="primary"
              :loading="loading"
              @click="onSaveClick"
            >
              <v-icon left>
                {{ mdiContentSave }}
              </v-icon>
              Salvesta
            </v-btn>
          </div>
          <v-btn
            v-else
            text
            small
            color="primary"
            @click="onEdit"
          >
            <v-icon left>
              {{ mdiPencil }}
            </v-icon>
            Muuda
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <GenericForm
      v-if="docData && editMode === formName"
      ref="form"
      v-model="valid"
      :field-components="fieldComponents"
      :doc-data="docData"
      @onFormChange="onFormChange"
    />
    <GenericFormInfoList
      v-else
      :field-components="fieldComponents"
      :doc-data="docData"
    />
  </v-card>
</template>
<script>
import { reactive, toRefs, ref } from '@vue/composition-api';
import { mdiPencil, mdiContentSave } from '@mdi/js';
import GenericForm from '../molecules/GenericForm.vue';
import GenericFormInfoList from '../molecules/GenericFormInfoList.vue';
import useDocument from '../../composables/firebase/use-document';

const vectorIcons = {
  mdiPencil,
  mdiContentSave,
};
export default {
  components: {
    GenericForm,
    GenericFormInfoList,
  },
  props: {
    formName: {
      type: String,
      default: 'formOpen',
    },
    editMode: {
      type: String,
      default: '',
    },
    cardTitle: {
      type: String,
      default: '',
    },
    fieldComponents: {
      type: Array,
      required: true,
    },
    docData: {
      type: Object,
      default: () => {},
    },
    docType: {
      type: String,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const form = ref(null);
    const { updateDocument, loading } = useDocument(props.docType);
    const state = reactive({
      valid: true,
      docUpdates: {},
    });
    const onFormChange = (val) => {
      state.docUpdates = { ...val };
    };
    const onSaveClick = async () => {
      await form.value.validate();
      if (!state.valid) return;
      updateDocument({
        id: props.docData.id,
        ...state.docUpdates,
      });
      emit('update:editMode', '');
    };
    const onEdit = () => {
      emit('update:editMode', props.formName);
    };
    const onCancelEdit = () => {
      emit('update:editMode', '');
    };
    return {
      ...toRefs(state),
      ...vectorIcons,
      loading,
      onFormChange,
      onSaveClick,
      onEdit,
      onCancelEdit,
      form,
    };
  },
};
</script>
