import 'firebase/compat/auth';
import {
  inject, provide, reactive, toRefs, computed,
} from '@vue/composition-api';
import { authUser } from '../../api';
import { updateListState } from '../store_helpers';

export const state = reactive({
  list: [],
});

export const actions = {
  async fetchAuthUsers() {
    try {
      state.list = await authUser.list();
    } catch (error) {
      // pass
    }
  },
  async patchAuthUser(id, user) {
    let response;
    try {
      response = await authUser.patch(id, user);
      updateListState(id, response, state.list);
    } catch (error) {
      // pass
    }
    return response;
  },
  async createNewUser(data) {
    let response;
    try {
      response = await authUser.post(data);
      // updateListState(id, response, state.list);
    } catch (error) {
      // pass
    }
    return response;
  },
  async deleteUser(id, method) {
    let response;
    try {
      response = await authUser.delete(id, { method });
    } catch (error) {
      // pass
    }
    return response;
  },
};
export const getters = {
  authUsers: computed(() => state.list),
};
export const key = Symbol('AuthUserStore');

export const provideAuthUser = () => {
  provide(key, {
    ...toRefs(state),
    ...toRefs(getters),
    ...actions,
  });
};
export default () => inject(key);
