<template>
  <v-dialog
    v-model="dialogState"
    :width="dialogWidth"
  >
    <!-- <component
      :is="dialogComponent"
      :is-open="dialogState"
    /> -->
    <component
      :is="componentFile"
      v-if="dialogComponent && dialogState"
    />
  </v-dialog>
</template>
<script>
import { computed, watch, isRef } from '@vue/composition-api';
import dialogModule from '@/store/modules/dialog';

export default {
  name: 'GenericDialog',
  components: {},
  setup() {
    const componentModule = 'organisms';
    const {
      dialogComponent,
      dialogWidth,
      dialogOpen,
      setDialog,
      dialogCloseAction,
      setDialogOptions,
    } = dialogModule();
    const dialogState = computed({
      get: () => dialogOpen.value,
      set: (val) => {
        setDialog(val);
      },
    });
    watch(dialogState, (value) => {
      if (value === false) {
        // on dialog close
        if (dialogCloseAction && dialogCloseAction.value) {
          // executing "on dialog close" action
          dialogCloseAction.value();
        }
        // reseting dialog state
        setDialogOptions();
      }
    });
    function importComponent(module, _name) {
      const name = isRef(_name) ? _name.value : _name;
      // this method-computed separate structure is needed to resolve some reactiviy bugs
      /* eslint-disable */
      return () => import('@/components/' + module + '/' + name + '/index.vue')
      /* eslint-enable */
    }

    const componentFile = computed(() => importComponent(componentModule, dialogComponent));
    return {
      dialogComponent,
      dialogWidth,
      dialogState,
      componentFile,
    };
  },
};
</script>
