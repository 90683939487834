<template>
  <v-dialog
    v-model="overlayState"
    class="elevation-0"
    content-class="overlay-dialog"
    overlay-opacity=".95"
  >
    <v-card>
      <v-progress-linear
        v-if="loading"
        class="progress-bar-position"
        indeterminate=""
      />
      <v-row
        no-gutters
        justify="space-between"
        align="center"
        class="fill-height"
      >
        <component
          :is="overlayOptions.component"
          :payload="overlayOptions.payload"
        />
      </v-row>
      <v-row
        no-gutters
        justify="space-between"
        align="center"
      />
    </v-card>
    <v-btn
      v-if="overlayOptions.payload.previous"
      class="previous-btn-position"
      icon
      color="white"
      x-large
      @click="onPreviousClick()"
    >
      <v-icon size="50">
        {{ mdiArrowLeftBoldBox }}
      </v-icon>
    </v-btn>
    <v-btn
      v-if="overlayOptions.payload.next"
      class="next-btn-position"
      color="white"
      icon
      x-large
      @click="onNextClick()"
    >
      <v-icon size="50">
        {{ mdiArrowRightBoldBox }}
      </v-icon>
    </v-btn>
    <v-btn
      color="white"
      icon
      x-large
      class="close-btn-position"
      @click="onCloseClick"
    >
      <v-icon size="50">
        {{ mdiClose }}
      </v-icon>
    </v-btn>
  </v-dialog>
</template>
<script>
import {
  ref, computed, onMounted, onUnmounted,
} from '@vue/composition-api';
import { mdiArrowLeftBoldBox, mdiArrowRightBoldBox, mdiClose } from '@mdi/js';
import DocumentPreview from '@/components/organisms/DocumentPreview/index.vue';
import dialogModule from '@/store/modules/dialog';

const vectorIcons = {
  mdiArrowLeftBoldBox, mdiArrowRightBoldBox, mdiClose,
};
export default {
  components: {
    DocumentPreview,
  },
  setup() {
    const loading = ref(false);
    const { overlayOptions, overlayOpen, setOverlay } = dialogModule();
    const overlayState = computed({
      get: () => overlayOpen.value,
      set: (val) => {
        setOverlay(val);
      },
    });
    const next = async () => {
      loading.value = true;
      await overlayOptions.value.payload.next();
      loading.value = false;
    };
    const previous = async () => {
      loading.value = true;
      await overlayOptions.value.payload.previous();
      loading.value = false;
    };
    const onNextClick = () => {
      Promise.resolve(next());
    };
    const onPreviousClick = () => {
      Promise.resolve(previous());
    };
    const onKeyRelease = (event) => {
      if (event.keyCode === 39 && overlayState.value) {
        Promise.resolve(next());
      }
      if (event.keyCode === 37 && overlayState.value) {
        Promise.resolve(previous());
      }
    };
    const onCloseClick = () => {
      overlayState.value = false;
    };
    onMounted(() => {
      document.addEventListener('keyup', onKeyRelease);
    });
    onUnmounted(() => {
      document.removeEventListener('keyup', onKeyRelease);
    });
    return {
      ...vectorIcons,
      loading,
      onNextClick,
      onPreviousClick,
      overlayOptions,
      overlayState,
      onCloseClick,
    };
  },
};
</script>
<style lang="scss">
    .next-btn-position {
        position: absolute;
        right: 10vw;
        bottom: 48vh;
        z-index: 999;
    }
    .previous-btn-position {
        position: absolute;
        left: 10vw;
        bottom: 48vh;
        z-index: 999;
    }
    .close-btn-position {
        position: absolute;
        top: 20px;
        right: 10vw;
    }
    .progress-bar-position {
        position:absolute;
        top: 0
    }
</style>
