export function formatShortDate(dateStr, type = 'DATE') {
  if (!dateStr) return null;
  const [year, month, day] = dateStr.split('-');
  if (type === 'YEAR') return year;
  if (type === 'MONTH') return `${month}.${year}`;
  if (type === 'DATE') return `${day}.${month}.${year}`;
  return dateStr;
}
export function formatCoetusDate(dateStr, short = false) {
  if (!dateStr) return null;
  const [year, month] = dateStr.split('-');
  if (month >= 2 && month < 9) {
    if (short) return `${year}/I`;
    return `${year} kevadsemester`;
  }
  if (short) return `${year}/II`;
  return `${year} sügissemester`;
}
