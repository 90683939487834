<template>
  <v-menu
    v-model="menuState"
    transition="scale-transition"
    offset-y
    min-width="290px"
    nudge-top="20"
    :close-on-content-click="false"
  >
    <template #activator="{ on }">
      <v-text-field
        :value="formattedDate"
        :dense="dense"
        :outlined="outlined"
        :prepend-icon="prependIcon"
        :label="label"
        readonly
        :clearable="clearable"
        :clear-icon="mdiClose"
        v-on="on"
        @click:clear="onDatePick('')"
      >
        <template #prepend>
          <slot name="prepend" />
        </template>
      </v-text-field>
      <!-- v-bind="attrs" -->
    </template>
    <v-date-picker
      ref="picker"
      :value="value"
      :min="min"
      :max="max"
      no-title
      scrollable
      first-day-of-week="1"
      locale="et-EE"
      @change="onDatePick"
      @click:year="onYearClick"
      @click:month="onMonthClick"
      @click:date="onDateClick"
    />
  </v-menu>
</template>
<script>
import {
  reactive, toRefs, watch, computed, ref,
} from '@vue/composition-api';
import { mdiClose } from '@mdi/js';
import { formatShortDate } from '../../helpers/date-helpers';

const vectorIcons = { mdiClose };
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    prependIcon: {
      type: String,
      default: '',
    },
    dense: {
      type: Boolean,
    },
    outlined: {
      type: Boolean,
    },
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'YEAR',
    },
    min: {
      type: String,
      default: '1884-01-01',
    },
    max: {
      type: String,
      default: new Date().toISOString().substr(0, 10),
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    formatFunc: {
      type: Function,
      default: () => {},
    },
  },
  setup(props, { emit, root }) {
    const picker = ref(null);
    const state = reactive({
      menuState: false,
      date: null,
    });
    const menuOpen = computed(() => state.menuState);
    const onDatePick = (val) => {
      state.date = val;
      emit('change', val);
    };
    watch(menuOpen, () => {
      if (picker && picker.value) {
        root.$nextTick(() => { picker.value.activePicker = 'YEAR'; });
      }
    });
    const onYearClick = (val) => {
      if (props.type === 'YEAR') {
        emit('change', String(val));
        picker.value.activePicker = 'YEAR';
        state.menuState = false;
      }
    };
    const onMonthClick = (val) => {
      if (props.type === 'MONTH') {
        emit('change', String(val));
        picker.value.activePicker = 'MONTH';
        state.menuState = false;
      }
    };
    const onDateClick = (val) => {
      if (props.type === 'DATE') {
        emit('change', String(val));
        picker.value.activePicker = 'DATE';
        state.menuState = false;
      }
    };
    const formattedDate = computed(() => {
      const date = state.date || props.value;
      if (props.formatFunc(date)) return props.formatFunc(date);
      return formatShortDate(date, props.type);
    });
    return {
      ...toRefs(state),
      ...vectorIcons,
      onDatePick,
      picker,
      onYearClick,
      onMonthClick,
      onDateClick,
      formattedDate,
    };
  },
};
</script>
