<template>
  <div>
    <v-avatar
      :size="size"
      :tile="tile"
    >
      <img :src="userAvatar || 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'">
    </v-avatar>
    <div class="edit-position-container">
      <div class="edit-position-inner">
        <v-btn
          v-if="editable && canEdit"
          id="pick-avatar"
          icon
          color="white"
        >
          <v-icon>{{ mdiPencilBox }}</v-icon>
        </v-btn>
      </div>
    </div>
    <AvatarCropper
      v-if="editable"
      trigger="#pick-avatar"
      :upload-handler="handleProfilePicUpload"
      :labels="{ submit: 'Lae üles', cancel: 'Tühista' }"
      @changed="onProfilePicChange"
    />
  </div>
</template>
<script>
import AvatarCropper from 'vue-avatar-cropper';
import {
  reactive, toRefs, watch, computed,
} from '@vue/composition-api';
import { mdiPencilBox } from '@mdi/js';
import storageModule from '../../store/modules/storage';
// import { isImageDark } from "../../helpers/image-helpers";
const vectorIcons = {
  mdiPencilBox,
};
export default {
  components: {
    AvatarCropper,
  },
  props: {
    userProfile: {
      type: Object,
      default: () => {},
    },
    tile: {
      type: Boolean,
    },
    size: {
      type: String,
      default: '25px',
    },
    editable: {
      type: Boolean,
    },
    canEdit: {
      type: Boolean,
    },
    pictureSize: {
      type: String,
      default: 'md',
    },
  },
  setup(props) {
    const { uploadFileAsPromise } = storageModule();
    const state = reactive({
      userAvatar: null,
      sourceFile: null,
    });
    const handleProfilePicUpload = async (val) => {
      const canvas = val.getCroppedCanvas({
        width: 250,
        height: 250,
      });
      state.userAvatar = canvas.toDataURL();
      canvas.toBlob(async (blob) => {
        const fileName = `avatar_${Date.now()}${state.sourceFile.name}`;
        await uploadFileAsPromise({
          file: blob,
          directory: `profiles/${props.userProfile.id}/profilePicture/`,
          name: fileName,
        });
      });
    };
    const userProfilePicture = computed(() => {
      if (props.userProfile && props.userProfile.profilePicture) return props.userProfile.profilePicture[props.pictureSize];
      return '';
    });
    watch(userProfilePicture, async (fileName) => {
      if (fileName) {
        state.userAvatar = fileName;
      } else {
        state.userAvatar = '';
      }
    });
    const onProfilePicChange = (val) => {
      state.sourceFile = val;
    };
    return {
      ...toRefs(state),
      ...vectorIcons,
      handleProfilePicUpload,
      onProfilePicChange,
    };
  },
};
</script>
<style lang="scss">
.edit-position-container{
    position: relative;
    height: 0;
    .edit-position-inner {
        position: absolute;
        bottom: 0;
        right:0;
    }
}

</style>
