<template>
  <div>
    <v-card
      v-if="userProfile && userProfile['work'] && userProfile['work'].length && editMode !== 'work'"
      class="ma-3"
    >
      <v-card-title class="py-3">
        <v-icon class="mr-4">
          {{ mdiDomain }}
        </v-icon>
        <span class="text-h6 text--primary">Töökohad ja ametid</span>
        <v-spacer />
        <v-btn
          v-if="canEdit"
          text
          small
          color="primary"
          @click="onEdit"
        >
          <v-icon left>
            {{ mdiPencil }}
          </v-icon>
          muuda
        </v-btn>
      </v-card-title>
      <v-card-text class="grey--text text--darken-3">
        <v-row
          v-for="(workposition, workIdx) in orderBy(userProfile['work'], 'from', 'to', true)"
          :key="'work-' + workIdx"
          no-gutters
        >
          <v-col
            class="shrink"
            cols="12"
            md="3"
          >
            {{ formatShortDate(workposition.from, "MONTH") }} -
            {{ formatShortDate(workposition.to, "MONTH") }}
          </v-col>
          <v-col
            class="grow"
            cols="12"
            md="9"
          >
            {{ workposition.position }},
            {{ workposition.company }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row
      v-else-if="editMode !== 'work' && canEdit"
      no-gutters
      justify="end"
      class="mx-3"
    >
      <v-btn
        text
        small
        color="primary"
        @click="onEdit"
      >
        <v-icon left>
          {{ mdiPencil }}
        </v-icon>
        Lisa töökoht
      </v-btn>
    </v-row>
    <DynamicRowsFormCard
      v-if="userProfile && editMode === 'work'"
      :field-components="workRowFields"
      :doc-data="userProfile"
      doc-type="user_profiles"
      doc-key="work"
      card-title="Töökoha lisamine"
      @saved="onCancelEdit"
      @cancelled="onCancelEdit"
    />
  </div>
</template>
<script>
import { mdiPencil, mdiDomain } from '@mdi/js';
import DynamicRowsFormCard from '../molecules/DynamicRowsFormCard.vue';
import { createField } from '../../helpers/form-helpers';
import { orderBy } from '../../helpers/list-helpers';
import { formatShortDate } from '../../helpers/date-helpers';

const vectorIcons = {
  mdiPencil,
  mdiDomain,
};
export default {
  components: {
    DynamicRowsFormCard,
  },
  props: {
    userProfile: {
      type: Object,
      default: () => {},
    },
    editMode: {
      type: String,
      default: '',
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const requiredRules = (label) => [(v) => !!v || `${label} on kohustuslik väli!`];
    const workRowFields = [
      createField({
        key: 'company',
        props: { label: 'Ettevõte', required: true, rules: requiredRules('Haridusasutus') },
        colProps: {
          cols: 12, sm: 12, md: 12, lg: 6,
        },
      }),
      createField({
        key: 'position',
        props: { label: 'Amet', required: true, rules: requiredRules('Eriala') },
        colProps: {
          cols: 12, sm: 12, md: 12, lg: 6,
        },
      }),
      createField({
        key: 'from',
        component: 'date-select-field',
        props: {
          label: 'Algus', required: true, type: 'MONTH', rules: requiredRules('Algus'),
        },
        colProps: {
          cols: 6, sm: 6, md: 6, lg: 4,
        },
      }),
      createField({
        key: 'to',
        component: 'date-select-field',
        props: { label: 'Lõpp', type: 'MONTH' },
        colProps: {
          cols: 6, sm: 6, md: 6, lg: 4,
        },
      }),
    ];
    const onEdit = () => {
      emit('update:editMode', 'work');
    };
    const onCancelEdit = () => {
      emit('update:editMode', '');
    };
    return {
      ...vectorIcons,
      workRowFields,
      orderBy,
      onEdit,
      onCancelEdit,
      formatShortDate,
    };
  },
};
</script>
