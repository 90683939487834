<template>
  <v-list dense>
    <template v-for="(field, index) in fieldComponents">
      <v-list-item
        v-if="getFieldText(field)"
        :key="index"
      >
        <v-list-item-icon class="py-1">
          <v-icon>{{ field.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle>{{ field.props.label }}</v-list-item-subtitle>
          <v-list-item-title>{{ getFieldText(field) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</template>
<script>
export default {
  props: {
    docData: {
      type: Object,
      default: () => {},
    },
    fieldComponents: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const isFunction = (obj) => !!(obj && obj.constructor && obj.call && obj.apply);
    const getFieldText = (field) => {
      if (field.hidden) return '';
      if (!props.docData) return '';
      const rawValue = props.docData[field.key];
      if (!rawValue) return '';

      // field value is object selected from list
      if (field && field.props && field.props.items && field.props['item-value']) {
        const relatedItem = field.props.items.find(
          (i) => i[field.props['item-value']] === props.docData[field.key],
        );
        if (isFunction(field.props['item-text'])) return field.props['item-text'](relatedItem);
        if (relatedItem) return relatedItem[field.props['item-text']];
        return rawValue[field.props['item-text']];
      }

      // field value is string or number
      if (['string', 'number'].includes(typeof rawValue)) {
        if (field.formatFunc && isFunction(field.formatFunc)) {
          // field has a formatting function
          return field.formatFunc(rawValue);
        }
        return rawValue;
      }
      return 'sumthin went wrong';
    };
    return {
      getFieldText,
    };
  },
};
</script>
