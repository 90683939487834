<template>
  <v-container class="fill-height fluid align-start">
    <v-container
      fluid
      class="grey lighten-4 fill-height"
    >
      <v-row
        justify="center"
        align="center"
      >
        <v-btn
          :loading="loading"
          @click="syncGroups"
        >
          SYNC GROUPS
        </v-btn>
        <v-btn
          :loading="loading"
          @click="syncGroupMembers"
        >
          SYNC GROUP MEMBERS
        </v-btn>
        <v-btn @click="sessionVerify()">
          sessionVerify
        </v-btn>
        <v-btn @click="sessionLogout()">
          sessionLogout
        </v-btn>
        <v-btn @click="postIdTokenToSessionLogin()">
          sessionLogin
        </v-btn>
        <v-btn @click="testCsrf()">
          csrfToken
        </v-btn>
        <v-btn @click="linkUserWithGoogle()">
          linkGUserWithGoogle
        </v-btn>
        <v-btn @click="unlinkUserWithGoogle()">
          unlinkGUserWithGoogle
        </v-btn>
        <v-btn @click="testFunction()">
          testFunction
        </v-btn>
      </v-row>
      <v-row class="pa-3">
        {{ textInput }}
        <v-text-field
          v-model="textInput"
          class="text-field-width"
        />
        <v-btn @click="removeUserFromGroups(textInput)">
          REMOVE USER FROM ALL GROUPS
        </v-btn>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
// import { mapActions, mapGetters } from 'vuex';

export default {
  props: {},
  data: () => ({
    textInput: '',
  }),
  computed: {
    // ...mapGetters('group', ['loading']),
  },
  methods: {
    // ...mapActions('group', ['syncGroups', 'syncGroupMembers', 'removeUserFromGroups']),
    // ...mapActions('auth', [
    //   'postIdTokenToSessionLogin',
    //   'sessionLogout',
    //   'sessionVerify',
    //   'testCsrf',
    //   'linkUserWithGoogle',
    //   'unlinkUserWithGoogle',
    //   'testFunction',
    // ]),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.text-field-width {
    max-width: 300px
}
</style>
