import { render, staticRenderFns } from "./ForgotPasswordSuccess.vue?vue&type=template&id=083ca3ae&scoped=true"
import script from "./ForgotPasswordSuccess.vue?vue&type=script&lang=js"
export * from "./ForgotPasswordSuccess.vue?vue&type=script&lang=js"
import style0 from "./ForgotPasswordSuccess.vue?vue&type=style&index=0&id=083ca3ae&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "083ca3ae",
  null
  
)

export default component.exports