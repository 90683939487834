import {
  computed, inject, provide, reactive, toRefs,
} from '@vue/composition-api';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import useDocument from '../../composables/firebase/use-document';
import { updateListState } from '../store_helpers';

export const state = reactive({
  libraryBooks: [],
  libraryCategories: [],
  groupLoading: false,
});
export const actions = {
  setLibraryBooks(books) {
    state.libraryBooks = [...books];
  },
  async fetchLibraryBooks({ category }) {
    if (!category) {
      state.libraryBooks = [];
      return;
    }
    let response;
    try {
      const db = firebase.firestore();
      const booksRef = db.collection('books');
      const query = booksRef.where('categoryId', '==', category);
      response = await query.get();
      const ret = [];
      response.forEach((doc) => {
        ret.push({ id: doc.id, ...doc.data() });
      });
      state.libraryBooks = ret;
    } catch (error) {
      console.log('err firestoree', error);
    }
  },
  async fetchLibraryCategories() {
    let response;
    try {
      const db = firebase.firestore();
      const query = db.collection('book_categories');
      // const query = categoriesRef.where("branch", "==", branch);
      response = await query.get();
      const ret = [];
      response.forEach((doc) => {
        ret.push({ id: doc.id, ...doc.data() });
      });
      state.libraryCategories = ret;
    } catch (error) {
      console.log('err firestoree', error);
    }
  },
  async saveCategory(category) {
    const categoryCollection = useDocument('book_categories');
    let categoryId;
    if (category.id) {
      await categoryCollection.setDocument(category);
      categoryId = category.id;
    } else {
      await categoryCollection.createDocument(category);
      categoryId = categoryCollection.documentData.value.id;
    }
    const result = { id: categoryId, ...category };
    updateListState(categoryId, result, state.libraryCategories);
    return result;
  },
  async saveBook(book) {
    const bookCollection = useDocument('books');
    let bookId;
    if (book.id) {
      await bookCollection.setDocument(book);
      bookId = book.id;
    } else {
      await bookCollection.createDocument(book);
      bookId = bookCollection.documentData.value.id;
    }
    const result = { id: bookId, ...book };
    updateListState(bookId, result, state.libraryBooks);
    return result;
  },
  async deleteBook(book) {
    const bookCollection = useDocument('books');
    if (book.id) {
      await bookCollection.deleteDocument(book.id);
      updateListState(book.id, false, state.libraryBooks);
    }
  },
  async saveBookCategory(bookCategory) {
    const categoryCollection = useDocument('book_categories');
    if (bookCategory.id) {
      categoryCollection.setDocument(bookCategory);
    } else {
      categoryCollection.createDocument(bookCategory);
    }
  },
};

export const getters = {
  categoryMap: computed(() => state.libraryCategories.reduce((map, category) => {
    const mapClone = { ...map };
    mapClone[category.id] = category;
    return mapClone;
  }, {})),
};

export const key = Symbol('LibraryStore');

export const provideLibrary = () => provide(key, {
  ...toRefs(state),
  ...getters,
  ...actions,
});

export default () => inject(key);
