import {
  inject, provide, reactive, toRefs,
} from '@vue/composition-api';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export const state = reactive({
  list: [],
  group_loading: false,
});
export const actions = {
  async fetchPhotosPath({ path = 'photos' } = {}) {
    const ret = [];
    try {
      let p = path;
      if (!p) return;
      if (p && p.startsWith('/')) {
        p = p.substring(1);
      }
      const response = await firebase
        .firestore()
        .collection('photos')
        .where('dirPath', '==', p)
        .get();
      response.forEach((doc) => {
        ret.unshift(doc.data());
      });
    } catch (error) {
      console.log('err firestoree', error);
    }
    // eslint-disable-next-line consistent-return
    return ret;
  },
};

export const getters = {};

export const key = Symbol('GalleryStore');

export const provideGallery = () => provide(key, {
  ...toRefs(state),
  ...getters,
  ...actions,
});

export default () => inject(key);
