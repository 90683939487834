<template>
  <v-app
    id="application"
  >
    <Dialog />
    <SnackBar />
    <ConfirmDialog />
    <Toolbar />
    <Drawer />
    <v-main>
      <Overlay />
      <router-view />

      <!-- <v-container class="fill-height fluid align-start"> </v-container> -->
    </v-main>
    <StorageUploadQueue />
  </v-app>
</template>
<script>
import { onMounted } from '@vue/composition-api';

import useBindCollection from '@/composables/firebase/use-bind-collection';
import profileModule from '@/store/modules/profile';
import groupModule from '@/store/modules/group';
import authModule from '@/store/modules/auth';
import Drawer from '@/components/organisms/MainNavigationDrawer/index.vue';
import Toolbar from '@/components/organisms/MainHeaderToolbar/index.vue';
import Dialog from '@/components/organisms/GlobalGenericDialog/index.vue';
import SnackBar from '@/components/organisms/GlobalGenericSnackbar/index.vue';
import StorageUploadQueue from '@/components/organisms/StorageUploadQueue/index.vue';
import ConfirmDialog from '@/components/organisms/GlobalConfirmDialog/index.vue';
import Overlay from '@/components/organisms/OverLay.vue';
import { rightsCookie } from '../api';

export default {
  components: {
    Drawer,
    Toolbar,
    SnackBar,
    Dialog,
    ConfirmDialog,
    Overlay,
    StorageUploadQueue,
  },
  setup(props, ctx) {
    const { list: profileList } = profileModule();
    const { fetchGroups } = groupModule();
    const { token } = authModule();
    const { bindCollection: BindProfileCollection } = useBindCollection(profileList.value, 'user_profiles', { orderBy: 'first_name' });
    // const { bindCollection: BindGroupCollection } = useBindCollection(group_list.value, "google_groups", {});
    const setSessionCookie = async () => {
      try {
        await rightsCookie.set({ idToken: token });
        if (ctx?.root?._route?.query?.redirect) {
          const redirectURL = decodeURI(ctx?.root?._route?.query?.redirect);
          window.location = redirectURL;
        }
      } catch (err) {
        // if (ctx?.root?._route?.query?.redirect) {
        //   const redirectURL = decodeURI(ctx?.root?._route?.query?.redirect);
        //   console.log('window open', redirectURL);
        // //   window.location = redirectURL;
        // }
        // pass
      }
    };
    onMounted(() => {
      BindProfileCollection();
      fetchGroups();
      // BindGroupCollection();
      setSessionCookie();
    });
  },
};
</script>
<style lang="scss">
#application {
    font-family: Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: #ECEFF1
}
</style>
