import { defineComponent } from '@vue/composition-api';
import { provideDialog } from './modules/dialog';
import { provideGroup } from './modules/group';
import { provideApp } from './modules/app';
import { provideAuth } from './modules/auth';
import { provideProfile } from './modules/profile';
import { provideMailArchive } from './modules/mailarchive';
import { provideStorage } from './modules/storage';
import { provideAuthUser } from './modules/auth_user';
import { provideGallery } from './modules/gallery';
import { provideLibrary } from './modules/library';
import { provideSearch } from './modules/search';

export default defineComponent({
  setup() {
    provideApp();
    provideGroup();
    provideDialog();
    provideAuth();
    provideProfile();
    provideMailArchive();
    provideStorage();
    provideAuthUser();
    provideGallery();
    provideLibrary();
    provideSearch();
    return {};
  },
  render(h) {
    return h('div', this.$slots.default);
  },
});
