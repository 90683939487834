<template>
  <v-col class="pa-0">
    <v-row no-gutters>
      <v-col
        class="grey lighten-2 cover-container"
      />
    </v-row>
    <v-container>
      <v-row
        no-gutters
        class="profile-container"
      >
        <v-col class="shrink">
          <v-card
            class="pa-1 mx-3 profile-picture-card"
          >
            <UserProfilePicture
              v-if="userProfile"
              :user-profile="userProfile"
              size="250px"
              picture-size="md"
              tile
              editable
              :can-edit="canEditProfile(userProfile.id).value"
            />
          </v-card>
        </v-col>
        <v-col>
          <v-row
            no-gutters
            align="center"
            class="fill-height flex-column"
          >
            <v-col>
              <span
                v-if="userProfile"
                class="text-h4 px-7"
              >
                {{ currentOrgTitle }} {{ userProfile.first_name }}
                {{ userProfile.last_name }}
              </span>
            </v-col>
            <v-col class="shrink">
              <v-row
                no-gutters
                class="flex-nowrap mr-3"
                align="center"
              >
                <v-tabs
                  slider-color="pohjala_blue"
                  color="pohjala_blue"
                  background-color="transparent"
                  show-arrows
                >
                  <v-tab
                    v-for="i in 1"
                    :key="i"
                  >
                    Profiil
                  </v-tab>
                </v-tabs>
                <!-- <v-btn text class="mt-3" @click="openAccountEmailSettings">
                                    <v-icon left>
                                        {{ mdiEmail }}
                                    </v-icon>
                                    E-posti seaded
                                </v-btn> -->
                <v-btn
                  v-if="userProfile && canEditProfile(userProfile.id).value"
                  text
                  class="mt-3"
                  @click="openEditAccountSettings"
                >
                  <v-icon left>
                    {{ mdiCog }}
                  </v-icon>
                  Konto seaded
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          lg="3"
          md="4"
          sm="12"
          cols="12"
        >
          <UserProfileInfoContactBlock
            v-if="userProfile"
            :edit-mode.sync="edit"
            :user-profile="userProfile"
            :can-edit="canEditProfile(userProfile.id).value"
          />
          <UserProfileInfoMembershipBlock
            v-if="userProfile"
            :edit-mode.sync="edit"
            :user-profile="userProfile"
            :can-edit="canEditProfile(userProfile.id).value"
          />
          <UserProfileInfoPersonalBlock
            v-if="userProfile"
            :edit-mode.sync="edit"
            :user-profile="userProfile"
            :can-edit="canEditProfile(userProfile.id).value"
          />
        </v-col>
        <v-col
          lg="9"
          md="8"
          sm="12"
          cols="12"
        >
          <!-- <v-card class="ma-3">
                        <v-card-title>Ametid põhjalas</v-card-title>
                        <v-list dense>
                            <v-list-item>
                                <v-list-item-title>asd</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card> -->
          <UserProfileEducationBlock
            v-if="userProfile"
            :edit-mode.sync="edit"
            :user-profile="userProfile"
            :can-edit="canEditProfile(userProfile.id).value"
          />
          <UserProfileWorkBlock
            v-if="userProfile"
            :edit-mode.sync="edit"
            :user-profile="userProfile"
            :can-edit="canEditProfile(userProfile.id).value"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-col>
</template>
<script>
/* eslint-disable no-underscore-dangle */
import {
  computed, reactive, toRefs, isRef,
} from '@vue/composition-api';
import {
  mdiPencil, mdiSchool, mdiCog, mdiEmail,
} from '@mdi/js';

import { orgTitles } from '@/helpers/common-data';
import profileModule from '@/store/modules/profile';
import dialogModule from '@/store/modules/dialog';
import authModule from '@/store/modules/auth';

import UserProfileInfoMembershipBlock from '@/components/organisms/UserProfileInfoMembershipBlock.vue';
import UserProfileInfoPersonalBlock from '@/components/organisms/UserProfileInfoPersonalBlock.vue';
import UserProfileInfoContactBlock from '@/components/organisms/UserProfileInfoContactBlock.vue';
import UserProfilePicture from '@/components/organisms/UserProfilePicture.vue';
import UserProfileEducationBlock from '@/components/organisms/UserProfileEducationBlock.vue';
import UserProfileWorkBlock from '@/components/organisms/UserProfileWorkBlock.vue';

const vectorIcons = {
  mdiPencil,
  mdiSchool,
  mdiCog,
  mdiEmail,
};

export default {
  components: {
    UserProfilePicture,
    UserProfileEducationBlock,
    UserProfileWorkBlock,
    UserProfileInfoMembershipBlock,
    UserProfileInfoPersonalBlock,
    UserProfileInfoContactBlock,
  },
  setup(props, ctx) {
    const { canEditProfile } = authModule();
    const { setDialogOptions, openDialog } = dialogModule();
    const { getProfile, currentUserProfile } = profileModule();
    const state = reactive({
      isEdit: true,
      edit: '',
      profileUpdates: {},
    });
    const userProfile = computed(() => {
      if (ctx.root._route.params.id) {
        return getProfile(ctx.root._route.params.id).value;
      }
      console.log('currentUserProfile', JSON.stringify(currentUserProfile.value));
      return currentUserProfile.value;
    });
    const currentOrgTitle = computed(() => {
      const uProfile = isRef(userProfile) ? userProfile.value : userProfile;
      return orgTitles[uProfile.org_title] && orgTitles[uProfile.org_title].label;
    });
    const openAccountEmailSettings = () => {
      setDialogOptions({
        component: 'EmailManagementDialog',
        // payload: item
      });
      openDialog();
    };
    const openEditAccountSettings = () => {
      setDialogOptions({
        component: 'UserProfileAccountDialog',
        // payload: item
      });
      openDialog();
    };
    return {
      ...toRefs(state),
      ...vectorIcons,
      canEditProfile,
      userProfile,
      orgTitles,
      openEditAccountSettings,
      openAccountEmailSettings,
      currentOrgTitle,
    };
  },
};
</script>
<style lang="scss">
.cover-container {
    height: 250px
}
.profile-container {
    margin-top: -62px
}
.profile-picture-card {
     margin-top: -160px
}
</style>
