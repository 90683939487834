import {
  reactive, toRefs, provide, inject,
} from '@vue/composition-api';

export const state = reactive({
  // GENERIC DIALOG
  dialogOpen: false,
  dialogWidth: 500,
  dialogComponent: '',
  dialogPayload: {},
  dialogActions: null,
  dialogCloseAction: null,
  // CONFIRM DIALOG
  confirmOpen: false,
  confirmOptions: {
    title: '',
    text: '',
    action: null,
    confirmText: '',
    cancelText: '',
    width: 300,
  },
  // SNACKBAR DIALOG
  snackbarOpen: false,
  snackbarOptions: {
    text: '',
    closeText: '',
    timeout: 3000,
    color: 'error',
    textColor: 'white',
  },
  // ERROR DIALOG
  errorOpen: false,
  errorOptions: {
    title: '',
    text: '',
    action: null,
    confirmText: '',
    cancelText: '',
    width: 300,
  },
  // OVERLAY
  overlayOpen: false,
  overlayOptions: {
    action: null,
    payload: {},
    width: 300,
  },
});

export const actions = {
  // dialog
  openDialog() {
    state.dialogOpen = true;
  },
  closeDialog() {
    state.dialogOpen = false;
  },
  setDialogOptions({
    width = 500, component = '', payload = {}, action = null, closeAction = null,
  } = {}) {
    state.dialogWidth = width;
    state.dialogComponent = component;
    state.dialogPayload = payload;
    state.dialogActions = action;
    state.dialogCloseAction = closeAction;
  },
  setDialog(val) {
    state.dialogOpen = val;
  },
  // confirm
  openConfirmDialog() {
    state.confirmOpen = true;
  },
  closeConfirmDialog() {
    state.confirmOpen = false;
  },
  setConfirmOptions({
    title = 'Title',
    text = 'text',
    confirmText = 'Confirm',
    cancelText = 'Cancel',
    width = 300,
    action,
  } = {}) {
    state.confirmOptions = {
      title,
      text,
      action,
      confirmText,
      cancelText,
      width,
    };
  },
  setConfirmDialog(val) {
    state.confirmOpen = val;
  },
  // snackbar
  openSnackBar() {
    state.snackbarOpen = true;
  },
  closeSnackBar() {
    state.errorOpen = false;
  },
  setSnackBarOptions({
    text = 'text',
    closeText = 'Close',
    color = 'error',
    textColor = 'white',
    timeout = 3000,
  } = {}) {
    state.snackbarOptions = {
      text,
      closeText,
      color,
      textColor,
      timeout,
    };
  },
  setSnackBar(val) {
    state.snackbarOpen = val;
  },
  // error
  openError() {
    state.errorOpen = true;
  },
  closeError() {
    state.errorOpen = false;
  },
  setError(val) {
    state.errorOpen = val;
  },
  setErrorOptions(error) {
    if (error && error.data && error.data.errors) {
      if (Array.isArray(error.data.errors)) {
        error.data.errors.forEach((err, index) => {
          if (index === 0) {
            state.errorOptions = { ...state.errorOptions, title: err };
          }
          if (index === 1) {
            state.errorOptions = { ...state.errorOptions, text: err };
          }
        });
        // for (const errorIndex in error.data.errors) {
        //   if (errorIndex === '0') {
        //     state.errorOptions = Object.assign(state.errorOptions, {
        //       title: error.data.errors['0'],
        //     });
        //   }
        //   if (errorIndex === '1') {
        //     state.errorOptions = Object.assign(state.errorOptions, {
        //       text: error.data.errors['1'],
        //     });
        //   }
        // }
        if (error.data.errors.length) {
          return true;
        }
      }
    } else if (error) {
      if (error.title) {
        Object.assign(state.errorOptions, {
          title: error.title,
        });
      }
      if (error.message) {
        Object.assign(state.errorOptions, {
          text: error.message,
        });
      }
    }
    return false;
  },
  // overlay
  openOverlay() {
    state.overlayOpen = true;
  },
  closeOverlay() {
    state.overlayOpen = false;
  },
  setOverlay(val) {
    state.overlayOpen = val;
  },
  setOverlayOptions({ component = '', payload = {} } = {}) {
    state.overlayOptions = {
      payload,
      component,
    };
  },
};
export const getters = {
  // current_account: computed(() => state.current_account),
  // available_accounts: computed(() => [...state.map.values()])
};

export const key = Symbol('DialogStore');

export const provideDialog = () => {
  provide(key, {
    ...toRefs(state),
    ...getters,
    ...actions,
  });
};

export default () => inject(key);
