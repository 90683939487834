import { ky } from './api-config';
import apiBaseUrl from './apiBaseUrl';
// // - BOOKMARKS
// async function getBookmarks(searchParams) {
//     return await ky.get(`api/bookmarks/`, { searchParams: searchParams }).json();
// }
// async function putBookmark(id, body) {
//     return await ky.put(`api/bookmarks/${id}`, { json: body }).json();
// }
// async function postBookmark(body) {
//     return await ky.post(`api/bookmarks/`, { json: body }).json();
// }
// async function deleteBookmark(id) {
//     return await ky.delete(`api/bookmarks/${id}`).json();
// }

function postGroupMembership({ groupId, email }) {
  return ky.post(`api/groups/${groupId}/members`, { json: { email } }).json();
}
function deleteGroupMembership({ groupId, email }) {
  return ky.delete(`api/groups/${groupId}/members/${email}`).json();
}
function deleteAllGroupMemberships({ userId }) {
  return ky.delete(`api/user/${userId}/deleteMemberships`).json();
}
function postGroupSync() {
  return ky.post('api/sync/Groups').json();
}
function postGroupMembershipsSync() {
  return ky.post('api/sync/GroupMembers').json();
}
function suggestRelatedEmail(email) {
  return ky.get(`api/users/suggest/${email}`).text();
}
function listAuthUsers() {
  return ky.get('api/users/').json();
}
function createNewUser(data) {
  return ky.post('api/users/', { json: data }).json();
}
function patchAuthUser(id, user) {
  return ky.patch(`api/users/${id}`, { json: user }).json();
}
function deleteUser(id, data) {
  return ky.delete(`api/users/${id}`, { json: data }).json();
}
function createStorageDirectory(data) {
  return ky.post('api/storage/directory', { json: data }).json();
}
function createUsernameCookie(data) {
  return ky.post('capi/set', {
    prefixUrl: apiBaseUrl.CAPI_BASE_URL,
    json: data,
  }).json();
}
function removeUsernameCookie() {
  return ky.post('capi/unset', {
    prefixUrl: apiBaseUrl.CAPI_BASE_URL,
  }).json();
}
// EXPORT
// bookmarks: { get: getBookmarks, put: putBookmark, post: postBookmark, delete: deleteBookmark },
const groupMembership = { post: postGroupMembership, delete: deleteGroupMembership };
const userMemberships = { delete: deleteAllGroupMemberships };
const groupSync = { post: postGroupSync };
const groupMembershipsSync = { post: postGroupMembershipsSync };
const auth = { suggest: suggestRelatedEmail };
const authUser = {
  list: listAuthUsers, patch: patchAuthUser, post: createNewUser, delete: deleteUser,
};
const storageDirectory = { post: createStorageDirectory };
const rightsCookie = { set: createUsernameCookie, unset: removeUsernameCookie };
export {
  groupMembership, userMemberships, groupSync, groupMembershipsSync, auth, authUser, storageDirectory, rightsCookie,
};
