<template>
  <v-app-bar
    color="pohjala_blue"
    dark
    app
  >
    <span class="text-h6 ml-3 mr-5">
      {{ $route.meta.title }}
    </span>
    <v-spacer />
    <v-autocomplete
      v-if="canSeeExperimental"
      :items="results"
      solo-inverted
      label="Search"
      :append-icon="mdiMagnify"
      hide-details
      height="40px"
      item-text="name"
      item-value="id"
      :search-input.sync="search"
    />
    <v-spacer />
  </v-app-bar>
</template>
<script>
import { ref, watch } from '@vue/composition-api';
import searchModule from '@/store/modules/search';
import authModule from '@/store/modules/auth';
import { mdiMagnify } from '@mdi/js';

const vectorIcons = { mdiMagnify };
export default {
  setup() {
    const { canSeeExperimental } = authModule();
    const { performSearch, results } = searchModule();
    const search = ref('');
    const onSearchInput = (val) => {
      performSearch({
        searchString: val,
        entityTypes: ['books'],
      });
    };
    watch(search, (val) => {
      onSearchInput(val);
    });
    return {
      ...vectorIcons,
      results,
      onSearchInput,
      search,
      canSeeExperimental,
    };
  },
};
</script>
