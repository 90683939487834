<template>
  <v-container class="fill-height fluid align-start">
    <!-- {{ messages }} -->
    <v-row
      v-for="(message, i) in messages"
      :key="i"
    >
      <v-col cols="12">
        <v-card class="pa-5">
          <v-card-title>
            <span>
              {{ getMessageHeader(message, "Subject") }}
            </span>
          </v-card-title>
          <v-card-subtitle>
            <span class="font-weight-bold">
              {{ getMessageHeader(message, "From") }}
            </span>
          </v-card-subtitle>
          <v-divider />
          <v-card-text v-if="message">
            <div v-html="getHtmlMessageFromParts(message.payload)" />
          </v-card-text>
          <v-card-actions>
            <v-chip
              v-for="(attachment, ai) in message.attachments"
              :key="'a' + ai"
              @click="onAttachmentClick(message, attachment)"
            >
              {{ attachment }}
            </v-chip>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import router from '@/router';
import mailArchiveModule from '@/store/modules/mailarchive';
import storageModule from '@/store/modules/storage';
import groupModule from '@/store/modules/group';

export default {
  components: {},
  props: {},
  setup() {
    const { groups } = groupModule();
    const { fetchThreadMessages } = mailArchiveModule();
    const { getBucketFileUrl } = storageModule();
    const state = reactive({
      messages: [],
      headers: [
        {
          text: 'Date',
          value: 'date',
        },
        {
          text: 'From',
          value: 'from',
        },
        {
          text: 'Subject',
          value: 'subject',
        },
        {
          text: '',
          value: 'snippet',
        },
      ],
      pagination: [
        25,
        50,
        100,
        {
          text: '$vuetify.dataIterator.rowsPerPageAll',
          value: -1,
        },
      ],
    });
    const loadMessages = async () => {
      state.messages = await fetchThreadMessages(router.currentRoute.params.threadId);
    };
    const onAttachmentClick = async (message, filename) => {
      const group = groups.value.find((g) => g.id === router.currentRoute.params.groupId);
      const currentUrl = await getBucketFileUrl({
        storagePath: `mail_attachments/${group.email}/${message.id}/${filename}`,
      });
      // todo:: better opening
      window.open(currentUrl, 'Download');
    };
    const getMessageHeader = (message, headerName) => {
      const resultHeader = message.payload.headers.find((header) => header.name === headerName);
      if (resultHeader) {
        return resultHeader.value;
      }
      return '';
    };
    const getHtmlMessageFromParts = (input) => {
      if (!input) {
        return 'failure';
      }
      if (input.parts) {
        const { parts } = input;
        let part;
        part = parts.find((p) => p.mimeType === 'text/html');
        if (part) {
          return Buffer.from(part.body.data, 'base64').toString('utf8');
        }
        part = parts.find((p) => p.mimeType === 'text/plain');
        if (part) {
          return Buffer.from(part.body.data, 'base64').toString('utf8');
        }
        part = parts.find((p) => p.mimeType === 'multipart/alternative');
        if (part) {
          if (!(part && part.parts)) {
            return 'ERROR';
          }
          return getHtmlMessageFromParts(part);
        }
        return 'ERROR';
      }
      if (input.mimeType === 'text/html') {
        return Buffer.from(input.body.data, 'base64').toString('utf8');
      } if (input.mimeType === 'text/plain') {
        return Buffer.from(input.body.data, 'base64').toString('utf8');
      }
      return 'ERROR';
    };
    onMounted(() => {
      loadMessages();
    });
    return {
      ...toRefs(state),
      getHtmlMessageFromParts,
      getMessageHeader,
      onAttachmentClick,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
