<template>
  <v-snackbar
    v-model="snackState"
    :timeout="snackbarOptions.timeout"
    :color="snackbarOptions.color"
    bottom
    left
  >
    <span :class="snackbarOptions.textColor + '--text'">{{ snackbarOptions.text }}</span>
    <v-btn
      :color="snackbarOptions.textColor"
      text
      @click="closeSnackBar"
    >
      {{ snackbarOptions.closeText }}
    </v-btn>
  </v-snackbar>
</template>
<script>
import { computed } from '@vue/composition-api';
import dialogModule from '@/store/modules/dialog';

export default {
  name: 'SnackBar',
  setup() {
    const {
      snackbarOptions, snackbarOpen, setSnackBar, closeSnackBar,
    } = dialogModule();
    const snackState = computed({
      get: () => snackbarOpen.value,
      set: (val) => {
        setSnackBar(val);
      },
    });
    return {
      snackState,
      snackbarOptions,
      closeSnackBar,
    };
  },
};
</script>
