import Ky from 'ky';
import { state as authState } from '../store/modules/auth';
import apiBaseUrl from './apiBaseUrl';
// API SETUP
export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts
      .pop()
      .split(';')
      .shift();
  }
  return null;
}

export const ky = Ky.extend({
  prefixUrl: apiBaseUrl.API_BASE_URL,
  credentials: 'include',
  retry: {
    limit: 2,
    methods: ['get'],
    // statusCodes: [413]
  },
  hooks: {
    beforeRequest: [
      (request, options) => {
        request.headers.set('Authorization', `Bearer ${authState.token}`);
        if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(options.method)) {
          // options.headers.set("X-Xsrftoken", getCookie("_xsrf"));
        }
      },
    ],
    // afterResponse: [
    //     async (_request, _options, response) => {
    //         if (response.ok === false) {
    //             const body = await response.json();
    //             const details = { request: _request, options: _options, response: response, data: body };
    //             if (!(body && body.error_type && body.error_type === "form_validation")) {
    //                 const handling_success = dialogActions.setErrorOptions(details);
    //                 if (handling_success) {
    //                     dialogActions.openError();
    //                 } else {
    //                     alert("AUTOMATIC HANDLING FAILED, DEBUG: \n\n" + JSON.stringify(body));
    //                 }
    //             }
    //             throw details;
    //         }
    //     }
    // ]
  },
});
