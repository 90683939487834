import {
  inject, provide, reactive, toRefs, computed,
} from '@vue/composition-api';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export const state = reactive({
  list: [],
});
export const actions = {
  async fetchGroupThreads(groupAddresses, { dateFrom, dateUntil }) {
    let threads;
    try {
      const snapshot = await firebase
        .firestore()
        .collection('group_thread_archive')
        .orderBy('date', 'desc')
        .where('to_addresses', 'array-contains-any', groupAddresses)
        .where('date', '>=', dateFrom)
        .where('date', '<=', dateUntil)
        .get();
      threads = snapshot.docs.map((doc) => doc.data());
      state.list = threads;
    } catch (error) {
      // pass
    } finally {
      // pass
    }
    return threads;
  },
  async fetchThreadMessages(threadId) {
    let response;
    try {
      const snapshot = await firebase
        .firestore()
        .collection('group_thread_archive')
        .doc(threadId)
        .collection('messages')
        .get();
      response = snapshot.docs.map((doc) => doc.data());
    } catch (error) {
      // pass
    }
    return response;
  },
};

export const getters = {
  threads: computed(() => state.list),
};
export const key = Symbol('MailArchiveStore');

export const provideMailArchive = () => provide(key, {
  ...toRefs(state),
  ...getters,
  ...actions,
});

export default () => inject(key);
