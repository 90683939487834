<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-row
      v-if="docData"
      no-gutters
      class="px-2 pt-6"
    >
      <v-col
        v-for="(field, index) in fieldComponents"
        :key="index"
        v-bind="field.colProps"
      >
        <component
          :is="field.component"
          :value="docData[field.key]"
          v-bind="field.props"
          color="primary"
          :format-func="field.formatFunc"
          @change="onFieldChange(field.key, $event)"
        >
          <template
            v-if="field.icon"
            #prepend
          >
            <v-icon>{{ field.icon }}</v-icon>
          </template>
        </component>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import AvatarCropper from 'vue-avatar-cropper';
import { reactive, toRefs, ref } from '@vue/composition-api';
import { VTextField, VSelect, VAutocomplete } from 'vuetify/lib';
import DateSelectField from './DateSelectField.vue';

export default {
  components: {
    VTextField,
    VSelect,
    VAutocomplete,
    AvatarCropper,
    DateSelectField,
  },
  props: {
    fieldComponents: {
      type: Array,
      required: true,
    },
    docData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const form = ref(null);
    const state = reactive({
      valid: true,
      entityUpdates: {},
    });
    const onFieldChange = (key, val) => {
      state.entityUpdates[key] = val;
      emit('onFormChange', state.entityUpdates);
    };
    const validate = async () => {
      await form.value.validate();
    };
    return {
      ...toRefs(state),
      form,
      onFieldChange,
      validate,
    };
  },
};
</script>
