<template>
  <v-col
    class="my-2"
    cols="12"
  >
    <v-select
      :value="selectedYear"
      :items="yearsList"
      outlined
      dense
      class="year-select"
      @change="onYearChange"
      @input="onYearChange"
    />
    <v-data-table
      :items="threads"
      :headers="headers"
      :items-per-page="-1"
      hide-default-header
      class="elevation-2"
      dense
    >
      <template #item="{ item }">
        <tr @click="onRowClick(item)">
          <td
            v-for="(header, hi) in headers"
            :key="'h' + hi"
            :style="header.style"
            :class="header.class"
          >
            <template v-if="header.value === 'from_headers'">
              <span>{{ formatSenders(item["from_headers"]) }} </span>
              <v-avatar
                v-if="item['messages_count'] > 1"
                color="grey lighten-2"
                size="15"
              >
                <span class="text-caption">
                  {{ item["messages_count"] }}
                </span>
              </v-avatar>
            </template>
            <template v-else-if="header.value === 'date'">
              <span class="text-caption">
                {{ formatDate(item["date"].toDate()) }}
              </span>
            </template>
            <template v-else-if="header.value === 'has_attachments'">
              <v-icon
                v-if="item['has_attachments']"
                color="grey"
              >
                {{ mdiAttachment }}
              </v-icon>
            </template>
            <template v-else>
              {{ item[header.value] }}
            </template>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-col>
</template>
<script>
import {
  reactive, toRefs, computed, watch,
} from '@vue/composition-api';
import {
  isThisYear, format, subYears, startOfYear, endOfYear,
} from 'date-fns';
import { mdiAttachment } from '@mdi/js';
import router from '@/router';
import mailArchiveModule from '@/store/modules/mailarchive';

const vectorIcons = { mdiAttachment };
export default {
  components: {},
  props: {
    groupAddresses: {
      required: true,
      type: Array,
    },
  },
  setup(props, ctx) {
    const { threads, fetchGroupThreads } = mailArchiveModule();
    const state = reactive({
      selected_year: new Date().getFullYear(),
      headers: [
        {
          text: 'From',
          value: 'from_headers',
        },
        {
          text: 'Subject',
          value: 'subject',
        },
        // {
        //     text: "",
        //     value: "snippet"
        // },
        {
          text: 'Attachements',
          value: 'has_attachments',
          class: 'px-1 text-right',
          style: 'width: 83px;',
        },
        {
          text: 'Date',
          value: 'date',
          class: 'pl-1 text-right',
          style: 'width: 83px;',
        },
      ],
      pagination: [
        25,
        50,
        100,
        {
          text: '$vuetify.dataIterator.rowsPerPageAll',
          value: -1,
        },
      ],
    });
    const yearsList = computed(() => {
      const list = [];
      const date = new Date();
      Array.from({ length: date.getFullYear() - 1999 }, (x, i) => { // from 2000 to current year
        const currentDate = subYears(date, i);
        list.push(currentDate.getFullYear());
        return null;
      });
      return list;
    });
    const selectedYear = computed(() => Number(ctx.root._route.query.year ?? new Date().getFullYear()));
    const formatSenders = (froms) => {
      let resultString = '';
      if (froms.length === 1) {
        const names = froms[0].split(/\s+/);
        if (names.length > 1) {
          names.pop();
          resultString = names.join(' ').replace(/['"]+/g, '');
        } else {
          resultString = names.join().replace(/['"]+/g, '');
        }
      } else if (froms.length > 1) {
        const uniqueForms = [...new Set(froms)];
        const namesList = [];
        uniqueForms.forEach((from) => {
          const names = from.split(/\s+/);
          namesList.push(names[0]);
        });
        resultString = namesList.join(', ').replace(/['"]+/g, '');
      }
      if (resultString.length > 20) {
        return resultString.substring(0, 20);
      }
      return resultString;
    };
    const formatDate = (date) => {
      if (isThisYear(date)) {
        return format(date, 'd. MMM ');
      }
      return format(date, 'dd.MM.yy');
    };
    const fetchThreads = () => {
      const dateFrom = startOfYear(new Date().setFullYear(selectedYear.value));
      const dateUntil = endOfYear(new Date().setFullYear(selectedYear.value));
      fetchGroupThreads(props.groupAddresses, { dateFrom, dateUntil });
    };
    const onYearChange = (val) => {
      router.push({ query: { ...ctx.root._route.query, year: val } }).catch(() => {});
    };
    const onRowClick = (item) => {
      router.push({ name: 'threadmessages', params: { threadId: item.id } }).catch(() => {});
    };
    watch(selectedYear, () => {
      fetchThreads();
    });
    return {
      ...toRefs(state),
      ...vectorIcons,
      threads,
      yearsList,
      selectedYear,
      formatSenders,
      formatDate,
      onRowClick,
      onYearChange,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.year-select {
    max-width: 250px
}
</style>
