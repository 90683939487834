<template>
  <v-row no-gutters>
    <v-col>
      <v-row justify="center">
        <v-col cols="auto">
          <v-card
            width="600"
            raised
          >
            <v-card-text>
              <v-select
                v-model="selectedCollectionType"
                :items="firestoreDocumentTypes"
                item-text="title"
                item-value="value"
                dense
                outlined
                :prepend-icon="mdiDatabase"
              />
              <v-select
                v-model="action"
                :items="actionTypes"
                item-text="title"
                item-value="value"
                dense
                outlined
                :prepend-icon="mdiFunction"
                @change="onActionChange"
              />
              <v-file-input
                v-if="action === 'uploadJson'"
                v-model="chosenFile"
                label="Lae JSON fail andmebaasi"
                outlined
                dense
                accept="application/json"
                :prepend-icon="mdiCodeJson"
                show-size
                :error-messages="[invalidJSONMessage]"
                :error="!!invalidJSONMessage"
                @change="onFileChange"
              />
              <v-select
                v-model="selectedSearchIndexFields"
                :disabled="!searchKeyItems.length"
                dense
                outlined
                label="Search index fields"
                :items="searchKeyItems"
                multiple
                :prepend-icon="mdiDatabaseSearch"
              />
              <v-select
                v-if="action === 'uploadJson'"
                v-model="idField"
                :disabled="!searchKeyItems.length"
                dense
                outlined
                label="Id field"
                :items="searchKeyItems"
                :prepend-icon="mdiDatabaseSearch"
              />
              <v-select
                v-if="action === 'uploadJson'"
                v-model="prefixField"
                :disabled="!searchKeyItems.length"
                dense
                outlined
                multiple
                label="Prefix field key"
                :items="searchKeyItems"
                :prepend-icon="mdiDatabaseSearch"
              />
              <template v-if="prefixField && prefixField.length && action === 'uploadJson'">
                <v-text-field
                  v-for="(field, index) in prefixField"
                  :key="index"
                  v-model="prefixValue[field]"
                  dense
                  outlined
                  :label="field + ' prefix'"
                />
              </template>

              <v-progress-linear :value="storedPercentage" />
              <v-row
                no-gutters
                justify="end"
              >
                <span>{{ savedDocsCount }} / {{ data.length }}</span>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                right
                @click="onActionStart"
              >
                Start
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import {
  ref, computed, reactive, toRefs,
} from '@vue/composition-api';
import firebase from 'firebase/compat/app';
import {
  mdiDatabase, mdiFunction, mdiCodeJson, mdiDatabaseSearch,
} from '@mdi/js';
import useDocument from '@/composables/firebase/use-document';
import 'firebase/compat/firestore';

const vectorIcons = {
  mdiDatabase, mdiFunction, mdiCodeJson, mdiDatabaseSearch,
};
export default {
  setup() {
    const db = firebase.firestore();
    const selectedCollectionType = ref('books');
    const searchIndexCollection = useDocument('search_index');
    const firestoreDoc = useDocument(selectedCollectionType);
    const state = reactive({
      chosenFile: null, // <- initialize the v-model prop
      action: 'uploadJson',
      data: [],
      savedDocsCount: 0,
      errorDocsCount: 0,
      selectedSearchIndexFields: [],
      idField: '',
      prefixField: [],
      prefixValue: {},
    });
    const invalidJSONMessage = computed(() => {
      if (!state.data) return 'No file loaded';
      if (!Array.isArray(state.data)) return 'JSON is not an array';
      if (!state.data.length) return 'JSON has no length';
      if (!state.data[0].id) return "No 'id' key in object";
      return '';
    });

    const firestoreDocumentTypes = [
      { title: 'Raamatukogu raamatud', value: 'books' },
      { title: 'Raamatukogu kategooriad', value: 'book_categories' },
    ];
    const actionTypes = [
      { title: 'Lae JSON fail andmebaasi', value: 'uploadJson' },
      { title: 'Loo andmebaasile otsinguindeksid', value: 'searchIndexes' },
    ];

    const onActionChange = async (val) => {
      if (val === 'searchIndexes') {
        const response = await db
          .collection(selectedCollectionType.value)
          .limit(1)
          .get();
        state.data = response.docs.map((doc) => {
          const data = doc.data();
          const { id } = doc;
          return {
            id,
            ...data,
          };
        });
      } else {
        state.data = [];
      }
    };
    const searchKeyItems = computed(() => {
      if (!state.data || !state.data.length) return [];
      return Object.keys(state.data[0]);
    });
    const storedPercentage = computed(() => (state.savedDocsCount / state.data.length) * 100);
    const readFile = () => {
      if (!state.chosenFile) {
        return;
      }
      const reader = new FileReader();
      reader.readAsText(state.chosenFile);
      reader.onload = () => {
        state.data = JSON.parse(reader.result);
      };
    };
    const onFileChange = () => {
      readFile();
    };

    function getAllSubstrings(str, s) {
      let i;
      let j;
      const result = [];
      const size = s || 0;
      for (i = 0; i < str.length; i += 1) {
        for (j = str.length; j - i >= size; j -= 1) {
          result.push(str.slice(i, j));
        }
      }
      return result;
    }
    const createSearchIndexes = async (row) => {
      if (!state.selectedSearchIndexFields.length) return;
      for (let i = 0; i < state.selectedSearchIndexFields.length; i += 1) {
        const fieldValueToBeIndexed = row[state.selectedSearchIndexFields[i]];
        if (typeof fieldValueToBeIndexed === 'string') {
          const normalized = fieldValueToBeIndexed
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
          const parts = normalized.match(/\b(\w+)\b/g);
          for (let pi = 0; pi < parts.length; pi += 1) {
            const word = parts[pi];
            const searchIndexdata = {
              id: word,
              text: word,
              textSubstrings: getAllSubstrings(word, 2),
              allUniqueIds: firebase.firestore.FieldValue.arrayUnion(row.id),
              matchingEntities: {
                [selectedCollectionType.value]: firebase.firestore.FieldValue.arrayUnion(row.id),
              },
            };
            try {
              // eslint-disable-next-line no-await-in-loop
              await searchIndexCollection.setDocument(searchIndexdata);
            } catch (err) {
              console.log('search index err', err);
            }
          }
        }
      }
    };
    const syncJSONToFirestore = async () => {
      if (invalidJSONMessage.value) throw new Error('validation fail');
      state.savedDocsCount = 0;
      for (let index = 0; index < state.data.length; index += 1) {
        const row = state.data[index];
        if (state.prefixField && state.prefixField.length) {
          state.prefixField.forEach((field) => {
            if (state.prefixValue[field]) {
              row[field] = `${state.prefixValue[field]}${row[field]}`;
            }
          });
        }
        if (state.idField) {
          row.id = state.data[index][state.idField];
        }
        try {
          // eslint-disable-next-line no-await-in-loop
          await firestoreDoc.setDocument(row);
          // eslint-disable-next-line no-await-in-loop
          await createSearchIndexes(row);
          state.savedDocsCount += 1;
        } catch (err) {
          state.errorDocsCount += 1;
          console.log(err);
        }
      }
    };
    const updateCollectionSearchIndexes = async () => {
      // todo :: "pagination" for large collections by 500 or something
      const collection = await db.collection(selectedCollectionType.value).get();
      state.savedDocsCount = 0;
      for (let index = 0; index < collection.docs.length; index += 1) {
        const data = collection.docs[index].data();
        const { id } = collection.docs[index];
        const row = { id, ...data };
        try {
          // eslint-disable-next-line no-await-in-loop
          await createSearchIndexes(row);
          state.savedDocsCount += 1;
        } catch (err) {
          state.errorDocsCount += 1;
          console.log(err);
        }
      }
    };
    const onActionStart = () => {
      switch (state.action) {
        case 'uploadJson':
          syncJSONToFirestore();
          break;
        case 'searchIndexes':
          updateCollectionSearchIndexes();
          break;
        default:
          break;
      }
    };
    return {
      ...toRefs(state),
      ...vectorIcons,
      onFileChange,
      onActionStart,
      onActionChange,
      actionTypes,
      firestoreDocumentTypes,
      selectedCollectionType,
      invalidJSONMessage,
      syncJSONToFirestore,
      storedPercentage,
      searchKeyItems,
    };
  },
};
</script>
