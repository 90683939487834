<template>
  <v-row
    class="form-container"
    no-gutters
  >
    <v-col class="xs12 py-2">
      <div class="text-h6 white--text">
        Parool edukalt lähtestatud!
      </div>
      <div class="text-caption white--text mt-3">
        Edasised juhised saatsime sulle postkasti aadressile {{ $route.params.email }}
      </div>
      <v-btn
        dark
        block
        small
        text
        class="my-3 pl-2 text-none"
        @click="onReturnToLoginClick"
      >
        <v-icon
          small
          left
        >
          {{ mdiArrowLeft }}
        </v-icon>
        Tagasi sisenema
      </v-btn>
    </v-col>
    <div class="error-message-placeholder" />
  </v-row>
</template>
<script>
import { mdiArrowLeft } from '@mdi/js';
import router from '../../../router';

const vectorIcons = { mdiArrowLeft };
export default {
  setup() {
    const onReturnToLoginClick = () => {
      router.replace({
        name: 'signin',
      });
    };
    return {
      ...vectorIcons,
      onReturnToLoginClick,
    };
  },
};
</script>
<style lang="scss" scoped>
.error-message-placeholder {
    height: 132px; width: 100%; display: block
}
.form-container {
    max-width: 300px;
}
</style>
