<template>
  <v-card class="ma-3">
    <v-card-title>
      <span class="text-h6 text--primary ">
        {{ cardTitle }}
      </span>
    </v-card-title>
    <!-- <v-card-subtitle>Töökohad ja ametid</v-card-subtitle> -->
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-col
        v-for="(row, rowindex) in formRows"
        :key="'form-row-' + rowindex"
        cols="12"
        class="pa-0"
      >
        <v-row
          no-gutters
          class="px-2 pt-2"
          justify="end"
        >
          <v-btn
            :disabled="loading"
            small
            text
            color="error"
            class="mx-2"
            @click="onDeleteRowClick(rowindex)"
          >
            <v-icon left>
              {{ mdiDelete }}
            </v-icon>
            Kustuta rida
          </v-btn>
        </v-row>
        <v-row
          no-gutters
          class="pa-2"
        >
          <v-col
            v-for="(field, colindex) in fieldComponents"
            :key="'form-col-' + colindex"
            v-bind="field.colProps"
          >
            <component
              :is="field.component"
              :value="row[field.key]"
              v-bind="field.props"
              color="primary"
              :format-func="field.formatFunc"
              @change="onFieldChange(rowindex, field.key, $event)"
            >
              <template
                v-if="field.icon"
                #prepend
              >
                <v-icon>{{ field.icon }}</v-icon>
              </template>
            </component>
          </v-col>
        </v-row>
        <v-divider />
      </v-col>
    </v-form>
    <v-row
      no-gutters
      class="pa-3"
    >
      <v-btn
        class="mx-1"
        color="primary"
        text
        :disabled="loading"
        @click="onAddRowClick"
      >
        <v-icon left>
          {{ mdiPlus }}
        </v-icon>
        Lisa rida
      </v-btn>
      <v-btn
        class="mx-1"
        color="primary"
        :loading="loading"
        @click="onSaveClick"
      >
        <v-icon left>
          {{ mdiContentSave }}
        </v-icon>
        Salvesta
      </v-btn>
      <v-btn
        class="mx-1"
        text
        color="primary"
        :disabled="loading"
        @click="onCancelClick"
      >
        Tühista
      </v-btn>
    </v-row>
  </v-card>
</template>
<script>
import { reactive, toRefs, ref } from '@vue/composition-api';
// import useDocument from "../../composables/firebase/use-document";
import { mdiDelete, mdiPlus, mdiContentSave } from '@mdi/js';
import { VTextField, VSelect, VAutocomplete } from 'vuetify/lib';
import DateSelectField from './DateSelectField.vue';
import useDocument from '../../composables/firebase/use-document';

const vectorIcons = {
  mdiDelete,
  mdiPlus,
  mdiContentSave,
};
export default {
  components: {
    VTextField,
    VSelect,
    VAutocomplete,
    DateSelectField,
  },
  props: {
    cardTitle: {
      type: String,
      default: '',
    },
    fieldComponents: {
      type: Array,
      required: true,
    },
    docData: {
      type: Object,
      required: true,
    },
    docKey: {
      type: String,
      required: true,
    },
    docType: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { updateDocument, loading } = useDocument(props.docType);
    const form = ref(null);
    const state = reactive({
      valid: true,
      formRows: props.docData[props.docKey] ? [...props.docData[props.docKey]] : [],
    });
    const onAddRowClick = async () => {
      await form.value.validate();
      if (!state.valid) return;
      state.formRows.push({});
    };
    const onDeleteRowClick = (index) => {
      state.formRows.splice(index, 1);
    };
    const onFieldChange = (index, key, val) => {
      state.formRows.splice(index, 1, { ...state.formRows[index], [key]: val });
    };
    const onSaveClick = async () => {
      await form.value.validate();
      if (!state.valid) return;
      await updateDocument({
        id: props.docData.id,
        [props.docKey]: state.formRows,
      });
      emit('saved');
    };
    const onCancelClick = () => {
      state.formRows = props.docData[props.docKey] ? [...props.docData[props.docKey]] : [];
      state.valid = true;
      emit('cancelled');
    };
    return {
      ...toRefs(state),
      ...vectorIcons,
      form,
      props,
      onAddRowClick,
      onDeleteRowClick,
      onFieldChange,
      onSaveClick,
      onCancelClick,
      loading,
    };
  },
};
</script>
