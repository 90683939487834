<template>
  <v-col
    class="my-2"
    cols="12"
  >
    <v-data-table
      :items="groupMembers"
      :headers="headers"
      :items-per-page="-1"
      class="elevation-2"
      dense
      hide-default-footer
    >
      <template #items="props">
        <tr>
          <td
            class="text-right font-weight-medium column-width-20"
          >
            {{ props.item.directMembersCount }}
            <v-icon
              small
              color="blue darken-4"
            >
              email
            </v-icon>
          </td>
          <td class="text-left">
            {{ props.item.email }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-col>
</template>
<script>
import { computed, reactive, toRefs } from '@vue/composition-api';
import profileModule from '@/store/modules/profile';

export default {
  components: {},
  props: {},
  setup(props, ctx) {
    const { getProfilesInGroup } = profileModule();
    // eslint-disable-next-line no-underscore-dangle
    const groupId = computed(() => ctx.root._route.params.groupId);
    const groupMembers = getProfilesInGroup(groupId.value);
    const state = reactive({
      headers: [
        {
          text: 'Staatus',
          value: 'org_title',
        },
        {
          text: 'Eesnimi',
          value: 'first_name',
        },
        {
          text: 'Perekonnanimi',
          value: 'last_name',
        },
        {
          text: 'Telefoni number',
          value: 'contact_phone',
        },
        {
          text: 'E-posti aadress',
          value: 'primary_email',
        },
        {
          text: 'Aadress',
          value: 'address',
        },
        {
          text: 'Koondis',
          value: 'org_branch',
        },
        {
          text: 'Tegevused',
          value: 'action',
          sortable: false,
        },
      ],
    });
    return { ...toRefs(state), groupMembers };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.column-width-20 {
    width: 20px;
}
</style>
