import firebase from 'firebase/compat/app';
// import "firebase/analytics";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// Initialize Firebase
firebase.initializeApp({
  apiKey: 'AIzaSyBAt00k-atetNROlYuGwdyf2wr2hKYsoE8',
  authDomain: 'pohjala-siseveeb-eu.firebaseapp.com',
  databaseURL: 'https://pohjala-siseveeb-eu.firebaseio.com',
  projectId: 'pohjala-siseveeb-eu',
  storageBucket: 'pohjala-siseveeb-eu.appspot.com',
  messagingSenderId: '265678499855',
});
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

// firebase.analytics();

firebase.getCurrentUser = () => new Promise((resolve, reject) => {
  const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
    unsubscribe();
    resolve(user);
  }, reject);
});

firebase.firestore().settings({});

export default firebase;
