const PROTOCOL = process.env.NODE_ENV === 'development' ? 'http' : 'https';
const EU_HOST = process.env.NODE_ENV === 'development'
  ? 'localhost:5001/pohjala-siseveeb-eu/europe-west1'
  : 'europe-west1-pohjala-siseveeb-eu.cloudfunctions.net';

const US_HOST = process.env.NODE_ENV === 'development'
  ? 'localhost:5001/pohjala-siseveeb-eu/us-central1/capi/'
  : 'siseveeb.pohjala.ee'; // only US hosted cloud functions allow api "renaming" which enables us to use proper secure cookies (capi = cookie api)

const API_BASE_URL = `${PROTOCOL}://${EU_HOST}`;
const CAPI_BASE_URL = `${PROTOCOL}://${US_HOST}`;

export default {
  API_BASE_URL,
  CAPI_BASE_URL,
};
