<template>
  <v-container
    fluid
    class="grey lighten-4 fill-height"
  >
    <v-row
      class="align-left"
      justify="center"
    >
      <v-col class="shrink">
        <v-card-title>
          TABEL AMETIKANDJA ALUSEL
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          />
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="mockup_officers"
          :items-per-page="15"
          :search="search"
        />
        <v-tooltip right>
          sadsad
        </v-tooltip>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// import { mapGetters } from 'vuex';

export default {
  props: {},
  data: () => ({
    search: '',
    headers: [
      {
        text: 'Nimi',
        value: 'name',
      },
      {
        text: 'Telefoni number',
        value: 'mobileNumber',
      },
      {
        text: 'E-posti aadress',
        value: 'email',
      },
      {
        text: 'Aadress',
        value: 'address',
      },
    ],
  }),
  computed: {
    // ...mapGetters('auth', ['mockup_officers']),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
