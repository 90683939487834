export const orgTitles = {
  'nrl!': { label: 'Nrl!', value: 'nrl!' },
  'ksv!': { label: 'Ksv!', value: 'ksv!' },
  'vil!': { label: 'Vil!', value: 'vil!' },
};

export const orgBranches = [
  {
    name: 'Tallinn',
    value: 'tallinn',
  },
  {
    name: 'Tartu',
    value: 'tartu',
  },
  {
    name: 'Vilkogu',
    value: 'vilkogu',
  },
];
export const educationLevels = {
  primary: {
    name: 'Põhiharidus',
    value: 'primary',
  },
  vocational: {
    name: 'Kutseharidus',
    value: 'vocational',
  },
  secondary: {
    name: 'Keskharidus',
    value: 'secondary',
  },
  bachelors: {
    name: 'Bakalaureus',
    value: 'bachelors',
  },
  masters: {
    name: 'Magister',
    value: 'masters',
  },
  doctoral: {
    name: 'Doktor',
    value: 'doctoral',
  },
};
