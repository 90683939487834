<template>
  <v-container class="fill-height align-start">
    <v-row
      no-gutters
      align="start"
      class="pb-10"
    >
      <v-chip-group
        key="statusFilter"
        :value="statusFilter"
        column
        multiple
        @change="onStatusFilterInput"
      >
        <v-chip
          v-for="status in statuses"
          :key="'status-' + status.value"
          filter
          :filter-icon="mdiCheck"
          :value="status.value"
          color="indigo"
          :outlined="statusFilter.indexOf(status.value) === -1"
          active-class="white--text"
          @click.stop=""
        >
          {{ status.text }}
        </v-chip>
      </v-chip-group>
      <v-divider
        vertical
        class="mr-2"
      />
      <v-chip-group
        key="branchFilter"
        v-model="branchFilter"
        column
        multiple
      >
        <v-chip
          v-for="branch in branches"
          :key="'branch-' + branch.value"
          filter
          :filter-icon="mdiCheck"
          :value="branch.value"
          color="indigo"
          :outlined="branchFilter.indexOf(branch.value) === -1"
          active-class="white--text"
          @click.stop=""
        >
          {{ branch.text }}
        </v-chip>
      </v-chip-group>
      <v-divider
        vertical
        class="mr-2"
      />
      <v-chip-group
        v-model="technicalFilter"
        column
        multiple
      >
        <v-chip
          v-for="technical in technicals"
          :key="'technical-' + technical.value"
          filter
          :filter-icon="mdiCheck"
          :value="technical.value"
          color="indigo"
          :outlined="technicalFilter.indexOf(technical.value) === -1"
          active-class="white--text"
        >
          {{ technical.text }}
        </v-chip>
      </v-chip-group>
      <v-spacer />
      <v-chip
        :input-value="hasDateOfDeathFilter"
        filter
        :filter-icon="mdiCheck"
        :value="true"
        color="indigo"
        :outlined="!hasDateOfDeathFilter"
        active-class="white--text"
        @click="hasDateOfDeathFilter = !hasDateOfDeathFilter"
      >
        Mälestuslehed
      </v-chip>
      <v-col cols="12">
        <v-row
          no-gutters
          class="mt-10"
        >
          <v-col
            class="my-2"
            cols="12"
          >
            <template v-for="(profiles, group) in filteredProfiles">
              <v-row
                v-if="profiles.length"
                :key="'divider-' + group"
                no-gutters
                align="center"
                class="my-3"
              >
                <v-col class="shrink">
                  <span class="pa-2 text-no-wrap">{{ getStatusGroupText(group) }} ({{ profiles.length }})</span>
                </v-col>
                <v-col><v-divider /></v-col>
              </v-row>
              <v-fade-transition
                :key="group"
                group
                tag="div"
                class="row ma-n2 no-gutters"
              >
                <v-col
                  v-for="(profile, i) in profiles"
                  :key="'profile-' + i"
                  xl="3"
                  lg="4"
                  md="6"
                  cols="12"
                  class="pa-2"
                >
                  <v-lazy class="elevation-1">
                    <v-sheet>
                      <v-list class="py-0">
                        <v-list-item
                          three-line
                          @click="onProfileRowClick(profile)"
                        >
                          <UserProfilePicture
                            :user-profile="profile"
                            size="55px"
                            picture-size="xs"
                            class="mr-4"
                          />
                          <v-list-item-content>
                            <v-list-item-title class="font-weight-medium">
                              {{ profile.org_title }} {{ profile.first_name }} {{ profile.last_name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <span
                                v-show="profile.primary_email"
                                class="text-caption mr-2"
                              >
                                <v-icon
                                  x-small
                                  color="primary"
                                >
                                  {{ mdiEmail }}
                                </v-icon>
                                {{ profile.primary_email }}
                              </span>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              <span
                                v-show="profile.contact_phone"
                                class="text-caption mr-2"
                              >
                                <v-icon
                                  x-small
                                  color="primary"
                                >
                                  {{ mdiPhone }}
                                </v-icon>
                                {{ profile.contact_phone }}
                              </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        <!-- <v-list-item-action>asd</v-list-item-action>
                    <v-list-item-action-text>asd</v-list-item-action-text> -->
                        </v-list-item>
                      </v-list>
                    </v-sheet>
                  </v-lazy>
                </v-col>
              </v-fade-transition>
            </template>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {
  computed, reactive, toRefs, onMounted,
} from '@vue/composition-api';
import { isBefore, parseISO } from 'date-fns';
import router from '@/router';
import profileModule from '@/store/modules/profile';
import UserProfilePicture from '@/components/organisms/UserProfilePicture.vue';
import {
  mdiEmail, mdiPhone, mdiAccount, mdiCheck,
} from '@mdi/js';
import { formatCoetusDate } from '@/helpers/date-helpers';

const vectorIcons = {
  mdiEmail, mdiPhone, mdiAccount, mdiCheck,
};
export default {
  components: {
    UserProfilePicture,
  },
  setup() {
    const { profiles, currentUserProfile } = profileModule();
    const state = reactive({
      branchFilter: [],
      statusFilter: [],
      technicalFilter: [],
      hasDateOfDeathFilter: false,
    });
    const branches = [
      { text: 'Tallinn', value: 'tallinn' },
      { text: 'Tartu', value: 'tartu' },
    ];
    const statuses = [
      { text: 'Vilistlane', value: 'vil!' },
      { text: 'Kaasvõitleja', value: 'ksv!' },
      { text: 'Noorliige', value: 'nrl!' },
    ];
    const getStatusGroupText = (val) => {
      const labels = {
        'ksv!': 'Kaasvõitlejad',
        'nrl!': 'Noorliikmed',
        'vil!': 'Vilistlased',
        pohjuseta: 'Põhjuseta välja arvatud',
        pohjusega: 'Põhjusega välja arvatud',
      };
      if (val in labels) return labels[val];
      return val;
    };
    const technicals = [
      { text: 'Ilma liikmestaatuseta(Vajab täpsustamist)', value: 'error' },
      { text: 'Põhjusega v/a', value: 'pohjusega' },
      { text: 'Põhjuseta v/a', value: 'pohjuseta' }];

    onMounted(() => {
      if (currentUserProfile.value.org_branch && currentUserProfile.value.org_title !== 'vil!') {
        state.branchFilter = [currentUserProfile.value.org_branch];
        state.statusFilter = ['ksv!', 'nrl!'];
      } else if (currentUserProfile.value.org_title && currentUserProfile.value.org_title === 'vil!') {
        state.statusFilter = [currentUserProfile.value.org_title];
      }
    });
    const filteredProfiles = computed(() => profiles.value.reduce((groups, profile) => {
      const branchFilter = !state.branchFilter.length || state.branchFilter.includes(profile.org_branch);
      const statusFilter = !state.statusFilter.length || state.statusFilter.includes(profile.org_title);
      const technicalFilter = !state.technicalFilter.length
      || (state.technicalFilter.includes('error') && [undefined, ''].includes(profile.org_title))
      || state.technicalFilter.includes(profile.org_title);
      const now = new Date();

      const dateOfDeathBeforeNow = !!profile.date_of_death && isBefore(parseISO(profile.date_of_death), now);

      const isAliveFilter = (!state.hasDateOfDeathFilter && !dateOfDeathBeforeNow) || (state.hasDateOfDeathFilter && dateOfDeathBeforeNow);
      if (!!branchFilter && !!statusFilter && !!technicalFilter && isAliveFilter) {
        const groupsClone = { ...groups };
        if ([undefined, ''].includes(profile.org_title)) {
          groupsClone.unknown.push(profile);
        } else {
          if (!(profile.org_title in groupsClone)) groupsClone[profile.org_title] = [];
          groupsClone[profile.org_title].push(profile);
        }
        return groupsClone;
      }
      return groups;
    }, {
      unknown: [], 'ksv!': [], 'nrl!': [], 'vil!': [], pohjusega: [], pohjuseta: [],
    }));
    const onStatusFilterInput = (val) => {
      state.statusFilter = val;
    };
    const onProfileRowClick = (row) => {
      router.push({
        name: 'userprofile',
        params: {
          id: row.id,
        },
      }).catch(() => {});
    };

    return {
      ...toRefs(state),
      ...vectorIcons,
      statuses,
      branches,
      technicals,
      filteredProfiles,
      onProfileRowClick,
      formatCoetusDate,
      getStatusGroupText,
      onStatusFilterInput,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
