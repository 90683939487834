import {
  inject, provide, reactive, toRefs,
} from '@vue/composition-api';

export const state = reactive({});

export const actions = {};

export const getters = {};

export const key = Symbol('AppStore');

export const provideApp = () => provide(key, {
  ...toRefs(state),
  ...toRefs(getters),
  ...actions,
});

export default () => inject(key);
