export function createField({
  key, component, props, colProps, icon, hidden, formatFunc,
}) {
  const defaultProps = { dense: true, outlined: true };
  const defaultColProps = { cols: 12, sm: 6, class: 'grow py-0 px-2' };
  const properties = Object.assign(defaultProps, props);
  const colProperties = Object.assign(defaultColProps, colProps);
  const comp = component || 'v-text-field';
  return {
    key, component: comp, icon, props: properties, colProps: colProperties, hidden, formatFunc,
  };
}
export function placeholder() {
  return false;
}
