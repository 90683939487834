<template>
  <v-navigation-drawer
    v-model="drawer"
    class="elevation-5"
    app
    permanent
    mini-variant-width="75"
    mobile-breakpoint="1"
    :mini-variant="mini"
    :expand-on-hover="mini"
    :key="mini"
    dark
    color="pohjala_blue"
  >
    <v-list nav>
      <v-list-item class="navigation-drawer-item">
        <v-list-item-avatar tile>
          <v-img :src="images['web/logo_no_text_svg.svg']" />
        </v-list-item-avatar>
        <v-list-item-title>
          <v-list-item-title class="text-h6 text-uppercase font-weight-medium">
            EÜS Põhjala
          </v-list-item-title>
        </v-list-item-title>
      </v-list-item>
    </v-list>
    <v-divider />
    <v-list
      dense
      nav
    >
      <v-list-item
        link
        class="navigation-drawer-item"
        @click="$router.push({ name: 'userprofile' }).catch(() => {})"
      >
        <v-list-item-avatar>
          <UserProfilePicture
            :user-profile="currentUserProfile"
            size="40px"
            picture-size="xs"
          />
          <!-- <v-img src="https://randomuser.me/api/portraits/women/85.jpg" /> -->
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ formatProfileDisplayName(currentUserProfile) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider />
    <v-list
      dense
      nav
      color="primary lighten-1"
    >
      <v-list-item
        v-for="(item, i) in permittedMenuItems"
        :key="i"
        class="navigation-drawer-item pl-4"
        link
        @click="onMenuItemClick(item)"
      >
        <v-list-item-icon>
          <v-icon>
            {{ item.meta.icon }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-title class="">
          {{ item.meta.title }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { computed, reactive, toRefs } from '@vue/composition-api';
import authModule from '@/store/modules/auth';
import profileModule from '@/store/modules/profile';
import storageModule from '@/store/modules/storage';
import router from '@/router';
// import UserProfilePicture from "../users/UserProfilePicture.vue";
import UserProfilePicture from '../UserProfilePicture.vue';

export default {
  components: {
    UserProfilePicture,
  },
  setup() {
    const { claims } = authModule();
    const { currentUserProfile, formatProfileDisplayName } = profileModule();
    const { getBucketFileUrl } = storageModule();
    const state = reactive({
      menu_name: 'main',
      drawer: null,
      mini: true,
      logo_url: '',
      images: {},
    });
    const menuItems = computed(() => router.options.routes
      .find((r) => r.name === 'Main')
      .children.filter((i) => i.meta.menus && i.meta.menus.includes(state.menu_name)));
    const permittedMenuItems = computed(() => menuItems.value.filter(
      (item) => item.meta.claims.includes('all')
                    || item.meta.claims.some(
                      (claim) => !!claims.value && claim in claims.value && claims.value[claim] === true,
                    ),
    ));
    const onMenuItemClick = (item) => {
      if (item && item.path) {
        router.push({
          name: item.name,
        }).catch(() => {});
      }
      if (item && item.meta && item.meta.action) {
        item.meta.action();
      }
    };
    const fetchImage = async (path) => {
      const imgUrl = await getBucketFileUrl({
        storagePath: path,
      });
      state.images = { ...state.images, [path]: imgUrl };
    };
    fetchImage('web/logo_no_text_svg.svg');
    return {
      ...toRefs(state),
      permittedMenuItems,
      currentUserProfile,
      formatProfileDisplayName,
      onMenuItemClick,
    };
  },
};
</script>
<style lang="scss" scoped>
.navigation-drawer-item {
    // without these, menu items will start juttering on menu close
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
}
</style>
