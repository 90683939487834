<template>
  <v-container class="fill-height fluid align-start">
    <v-row
      class="fill-height cols mt-10"
      no-gutters
    >
      <v-col cols="12">
        <!-- {{ directoryItems }} -->
        <v-card class="pa-2">
          <v-row>
            <v-col cols="12">
              <v-treeview
                v-model="tree"
                :active.sync="active"
                :items="directoryItems"
                :load-children="loadDirectoryChildren"
                :open.sync="open"
                activatable
                color="pohjala_blue"
                open-on-click
                transition
                item-key="fullPath"
                dense
              >
                <template #prepend="{ item, open }">
                  <v-icon
                    v-if="!item.file"
                    color="pohjala_blue"
                  >
                    {{ open ? mdiFolderOpen : mdiFolder }}
                  </v-icon>
                  <v-icon
                    v-else-if="fileTypes[item.file]"
                    :color="fileTypes[item.file]['color']"
                  >
                    {{ fileTypes[item.file]["icon"] }}
                  </v-icon>
                  <v-icon
                    v-else
                    color="pohjala_blue"
                  >
                    {{ mdiFileOutline }}
                  </v-icon>
                </template>
                <template #append="{ item }">
                  <div class="treeview-actions">
                    <template v-if="!item.file">
                      <!-- <v-btn icon @click.stop="">
                                            <v-icon color="red">
                                                {{ mdiDelete }}
                                            </v-icon>
                                        </v-btn>
                                        <v-btn icon @click.stop="">
                                            <v-icon color="pohjala_blue">
                                                {{ mdiFolderPlusOutline }}
                                            </v-icon>
                                        </v-btn> -->
                      <v-btn icon>
                        <v-icon
                          color="pohjala_blue"
                          @click.stop="onUploadClick(item)"
                        >
                          {{ mdiUpload }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <template v-else>
                      <!-- <v-btn icon>
                                            <v-icon color="red" @click.stop="">
                                                {{ mdiDelete }}
                                            </v-icon>
                                        </v-btn> -->
                    </template>
                  </div>
                </template>
              </v-treeview>
            </v-col>

            <!-- <v-col cols="8">
                        <div
                            v-if="!selected"
                            class="title grey--text text--lighten-1 font-weight-light"
                            style="align-self: center;"
                        >
                            Select a User
                        </div>
                        <template v-else>
                            <object
                                v-if="allowed_pdf_extensions.includes(current_file_ext)"
                                :data="currentUrl"
                                type="application/pdf"
                                width="100%"
                                height="600"
                            >
                                <a href="currentUrl">Download</a>
                            </object>
                            <v-img
                                v-if="allowed_image_extensions.includes(current_file_ext)"
                                :src="currentUrl"
                                contain
                                aspect-ratio="1.78"
                            />
                        </template>
                    </v-col> -->
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
/* eslint-disable no-underscore-dangle */
import {
  computed, watch, reactive, toRefs, onMounted,
} from '@vue/composition-api';
import {
  mdiFolderOpen, mdiFolder, mdiFileOutline, mdiDelete, mdiFolderPlusOutline, mdiUpload,
} from '@mdi/js';
import fileTypes from '@/composables/use-file-type-definitions';
import storageModule from '@/store/modules/storage';
import dialogModule from '@/store/modules/dialog';

const vectorIcons = {
  mdiFolderOpen,
  mdiFolder,
  mdiFileOutline,
  mdiDelete,
  mdiFolderPlusOutline,
  mdiUpload,
};
export default {
  components: {},
  props: {},
  setup(props, ctx) {
    const { directoryItems, getBucketFileUrl, fetchDirectoryItem } = storageModule();
    const { openDialog, setDialogOptions } = dialogModule();
    const state = reactive({
      currentUrl: '',
      tree: [],
      active: [],
      open: [],
      users: [],
      items: [],
    });

    const selected = computed(() => {
      if (!state.active.length) return undefined;
      return state.active[0];
    });
    const activeComp = computed(() => state.active);
    const onItemClick = async (val) => {
      const currentUrl = await getBucketFileUrl({
        storagePath: val,
      });
      setDialogOptions({
        component: 'FileDownloadForm',
        payload: {
          file: val,
          url: currentUrl,
        },
      });
      openDialog();
    };
    const loadDirectoryChildren = async (item) => {
      const rootpath = 'documents';
      await fetchDirectoryItem({
        item,
        rootpath,
      });
    };
    loadDirectoryChildren({
      rootpath: 'documents',
    });
    watch(activeComp, (newactive, oldactive) => {
      if (newactive && newactive.length === 0 && oldactive && oldactive.length !== 0) {
        state.active = oldactive;
      }
      if (selected.value) {
        onItemClick(selected.value);
      }
    });
    const onUploadClick = (directoryItem) => {
      setDialogOptions({
        width: 400,
        component: 'FileUploadForm',
        payload: { directoryItem },
      });
      openDialog();
    };
    onMounted(() => {
      if (ctx.root._route.path.startsWith('/failid/')) {
        const path = ctx.root._route.path.replace('/failid/', '');
        if (path.length) {
          const filePath = `documents/${path}`;
          onItemClick(decodeURI(filePath));
        }
      } else if (ctx.root._route.path.startsWith('/docs/')) {
        const path = ctx.root._route.path.replace('/docs/', '');
        if (path.length) {
          const filePath = `documents/docs/${path}`;
          onItemClick(decodeURI(filePath));
        }
      } else if (ctx.root._route.path.startsWith('/~')) {
        const path = ctx.root._route.path.replace('/~', '');
        if (path.length) {
          const filePath = `documents/liikmete_dokumendid_ftp_vana/${path}`;
          onItemClick(decodeURI(filePath));
        }
      }
    });
    return {
      ...toRefs(state),
      ...vectorIcons,
      directoryItems,
      fileTypes,
      selected,
      onUploadClick,
      loadDirectoryChildren,
    };
  },
};
</script>
<style scoped lang="scss">
/* .v-treeview-node .treeview-actions {
        visibility: hidden;
        opacity: 0;
    }

    .v-treeview-node:hover .treeview-actions {
        visibility: visible;
        opacity: 1;
    } */
</style>
