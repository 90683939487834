<template>
  <v-dialog
    v-model="dialogState"
    :width="confirmOptions.width"
    :max-width="confirmOptions.width"
    persistent
  >
    <v-card>
      <v-card-title class="text-h5">
        {{ confirmOptions.title }}
      </v-card-title>
      <v-card-text>{{ confirmOptions.text }}</v-card-text>
      <v-card-actions class="pa-3">
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="closeConfirmDialog()"
        >
          {{ confirmOptions.cancelText }}
        </v-btn>
        <v-btn
          color="primary inverted--text"
          @click="confirm()"
        >
          {{ confirmOptions.confirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { computed } from '@vue/composition-api';
import dialogModule from '@/store/modules/dialog';

export default {
  name: 'ConfirmDialog',
  setup() {
    const {
      confirmOptions, confirmOpen, setConfirmDialog, closeConfirmDialog,
    } = dialogModule();
    const dialogState = computed({
      get: () => confirmOpen.value,
      set: (val) => {
        setConfirmDialog(val);
      },
    });
    const confirm = () => {
      confirmOptions.value.action();
      closeConfirmDialog();
    };
    return {
      dialogState,
      confirmOptions,
      confirm,
      closeConfirmDialog,
    };
  },
};
</script>
