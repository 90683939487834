import { required, email, max } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';

extend('required', {
  ...required,
  message: 'Väli on kohustuslik',
});

extend('max', {
  ...max,
  message: 'This field must be {length} characters or less',
});

extend('email', {
  ...email,
  message: 'E-mail peab olema korrektses formaadis',
});
