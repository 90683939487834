<template>
  <v-container class="fill-height fluid align-start">
    <v-row
      no-gutters
      align="baseline"
      class="mt-10"
    >
      <v-col>
        <v-text-field
          v-model="search"
          :append-icon="mdiMagnify"
          label="Search"
          single-line
          class="mt-0"
          hide-details
        />
      </v-col>
      <v-col class="text-right">
        <v-btn
          color="primary"
          @click="onCreateUserClick"
        >
          <v-icon left>
            {{ mdiPlus }}
          </v-icon>Lisa uus liige
        </v-btn>
      </v-col>
      <v-col
        class="my-2"
        cols="12"
      >
        <v-progress-linear
          v-if="loading"
          :indeterminate="true"
          color="pohjala_blue"
        />
        <v-data-table
          :headers="headers"
          :items="authUsers"
          :items-per-page="-1"
          :search="search"
          dense
          sort-by="first_name"
          class="elevation-2"
          @click:row="onRowClick"
        >
          <template #item.customClaims="{ item }">
            <template v-if="item.customClaims">
              <v-chip
                v-for="([k], key) in Object.entries(item.customClaims).filter(
                  ([k, v]) => v === true
                )"
                :key="key"
                color="blue"
                dark
                x-small
                label
                class="text-capitalize"
              >
                {{ k }}
              </v-chip>
            </template>
            <template v-else>
              <v-chip
                color="red"
                dark
                x-small
                label
              >
                Missing
              </v-chip>
            </template>
          </template>
          <template #item.actions="{ item }">
            <v-row
              no-gutters
              justify="end"
            >
              <v-btn
                icon
                color="error"
                small
                @click="onDeleteUserClick(item)"
              >
                <v-icon small>
                  {{ mdiDelete }}
                </v-icon>
              </v-btn>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import { mdiPlus, mdiDelete, mdiMagnify } from '@mdi/js';
import dialogModule from '@/store/modules/dialog';
import AuthUserModule from '@/store/modules/auth_user';

const vectorIcons = { mdiPlus, mdiDelete, mdiMagnify };
export default {
  setup() {
    const { setDialogOptions, openDialog } = dialogModule();
    const { authUsers, fetchAuthUsers } = AuthUserModule();
    const state = reactive({
      strings: {
        eys_pohjala_liikmed: 'Seltsi liikmed',
      },
      search: '',
      headers: [
        {
          text: 'UID',
          value: 'uid',
        },
        {
          text: 'email',
          value: 'email',
        },
        {
          text: 'emailVerified',
          value: 'emailVerified',
        },
        {
          text: 'displayName',
          value: 'displayName',
        },
        {
          text: 'disabled',
          value: 'disabled',
        },
        {
          text: 'lastSignInTime',
          value: 'metadata.lastSignInTime',
        },
        {
          text: 'claims',
          value: 'customClaims',
        },
        {
          text: 'actions',
          value: 'actions',
        },
      ],
    });
    onMounted(() => {
      fetchAuthUsers();
    });
    const calcClaims = (claims) => Object.values(claims).filter((v) => v === true);
    const onRowClick = (item) => {
      console.log('open auth user form', item);
      // this.openDialog({
      //     width: 400,
      //     component: "AuthUserForm",
      //     payload: item
      // });
    };
    const onCreateUserClick = () => {
      setDialogOptions({
        component: 'EditAuthUserForm',
      });
      openDialog();
    };
    const onDeleteUserClick = (item) => {
      setDialogOptions({
        component: 'DeleteAuthUserDialog',
        payload: item,
      });
      openDialog();
    };
    return {
      ...toRefs(state),
      ...vectorIcons,
      authUsers,
      calcClaims,
      onRowClick,
      onCreateUserClick,
      onDeleteUserClick,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
